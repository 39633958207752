// // @mui material components
// import Tooltip from "@mui/material/Tooltip";

// // Mind Escape Dashboard React components
// import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";
// import SuiAvatar from "components/SuiAvatar";
// import SuiProgress from "components/SuiProgress";

// // Custom styles for the Projects
// import styles from "layouts/dashboard/components/Projects/styles";

// // Images
// import logoXD from "assets/images/small-logos/logo-xd.svg";
// import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
// import logoSlack from "assets/images/small-logos/logo-slack.svg";
// import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
// import logoJira from "assets/images/small-logos/logo-jira.svg";
// import logoInvesion from "assets/images/small-logos/logo-invision.svg";
// import team1 from "assets/images/team-1.jpg";
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";

// export default function data() {
//   const classes = styles();

//   const avatars = (members) =>
//     members.map(([image, name]) => (
//       <Tooltip key={name} title={name} placeholder="bottom">
//         <SuiAvatar src={image} alt="name" size="xs" customClass={classes.projects_tableAvatar} />
//       </Tooltip>
//     ));

//   return {
//     columns: [
//       { name: "companies", align: "left" },
//       { name: "members", align: "left" },
//       { name: "budget", align: "center" },
//       { name: "completion", align: "center" },
//     ],

//     rows: [
//       {
//         companies: [logoXD, "Mind Escape XD Version"],
//         members: (
//           <SuiBox display="flex" py={1}>
//             {avatars([
//               [team1, "Ryan Tompson"],
//               [team2, "Romina Hadid"],
//               [team3, "Alexander Smith"],
//               [team4, "Jessica Doe"],
//             ])}
//           </SuiBox>
//         ),
//         budget: (
//           <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//             $14,000
//           </SuiTypography>
//         ),
//         completion: (
//           <SuiBox width="8rem" textAlign="left">
//             <SuiProgress value={60} color="info" gradient />
//           </SuiBox>
//         ),
//       },
//       {
//         companies: [logoAtlassian, "Add Progress Track"],
//         members: (
//           <SuiBox display="flex" py={1}>
//             {avatars([
//               [team2, "Romina Hadid"],
//               [team4, "Jessica Doe"],
//             ])}
//           </SuiBox>
//         ),
//         budget: (
//           <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//             $3,000
//           </SuiTypography>
//         ),
//         completion: (
//           <SuiBox width="8rem" textAlign="left">
//             <SuiProgress value={10} color="info" gradient />
//           </SuiBox>
//         ),
//       },
//       {
//         companies: [logoSlack, "Fix Platform Errors"],
//         members: (
//           <SuiBox display="flex" py={1}>
//             {avatars([
//               [team1, "Ryan Tompson"],
//               [team3, "Alexander Smith"],
//             ])}
//           </SuiBox>
//         ),
//         budget: (
//           <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//             Not set
//           </SuiTypography>
//         ),
//         completion: (
//           <SuiBox width="8rem" textAlign="left">
//             <SuiProgress value={100} color="success" gradient />
//           </SuiBox>
//         ),
//       },
//       {
//         companies: [logoSpotify, "Launch our Mobile App"],
//         members: (
//           <SuiBox display="flex" py={1}>
//             {avatars([
//               [team4, "Jessica Doe"],
//               [team3, "Alexander Smith"],
//               [team2, "Romina Hadid"],
//               [team1, "Ryan Tompson"],
//             ])}
//           </SuiBox>
//         ),
//         budget: (
//           <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//             $20,500
//           </SuiTypography>
//         ),
//         completion: (
//           <SuiBox width="8rem" textAlign="left">
//             <SuiProgress value={100} color="success" gradient />
//           </SuiBox>
//         ),
//       },
//       {
//         companies: [logoJira, "Add the New Pricing Page"],
//         members: (
//           <SuiBox display="flex" py={1}>
//             {avatars([[team4, "Jessica Doe"]])}
//           </SuiBox>
//         ),
//         budget: (
//           <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//             $500
//           </SuiTypography>
//         ),
//         completion: (
//           <SuiBox width="8rem" textAlign="left">
//             <SuiProgress value={25} color="info" gradient />
//           </SuiBox>
//         ),
//       },
//       {
//         companies: [logoInvesion, "Redesign New Online Shop"],
//         members: (
//           <SuiBox display="flex" py={1}>
//             {avatars([
//               [team1, "Ryan Tompson"],
//               [team4, "Jessica Doe"],
//             ])}
//           </SuiBox>
//         ),
//         budget: (
//           <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//             $2,000
//           </SuiTypography>
//         ),
//         completion: (
//           <SuiBox width="8rem" textAlign="left">
//             <SuiProgress value={40} color="info" gradient />
//           </SuiBox>
//         ),
//       },
//     ],
//   };
// }

/* eslint-disable react/prop-types */
// @mui material components
// import Icon from "@mui/material/Icon";
import React from "react";
// Mind Escape Dashboard React components
// import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";
// import SuiProgress from "components/SuiProgress";
// import MyStatsTableData2 from "layouts/tables/data/MyStatsTableData2";
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import { borderColor, borderTopColor, color } from "@mui/system";
import Tooltip from '@material-ui/core/Tooltip';
import { useEffect, useState } from "react";
import styled from "styled-components";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Modal from 'react-modal';
import InputLabel from '@mui/material/InputLabel';
import ButtonGroup from '@mui/material/ButtonGroup';
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// import { DataGrid } from '@mui/x-data-grid';
import { useAuth } from "auth-context/auth.context";
// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//     "&:not([multiple]) option": {
//       background: "yellow"
//     }
//   }
// }));

//     <SuiBox display="flex" alignItems="center">
//       <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//         {value}&nbsp;
//       </SuiTypography>
//       <SuiBox width="8rem">
//         <SuiProgress value={value} color={color} gradient noLabel />
//       </SuiBox>
//     </SuiBox>
//   );
// }

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // zindex:-1,
  },
};

// Modal.setAppElement('#yourAppElement');

export default function Table4() {
  // const [OCEAN,setOCEAN ] = React.useState('');
  const [Τrows, setTRows] = React.useState([]);
  const [Τrows3, setTRows3] = React.useState([]);

  const [keys, setKeys] =useState(0);
  const [keysUsed, setKeysUsed] =useState(0);

  // const [Mrows, setMRows] = React.useState([]);
  // const [O, setO] = React.useState();
  // const [C, setC] = React.useState();
  // const [E, setE] = React.useState();
  // const [A, setA] = React.useState();
  // const [N, setN] = React.useState();

  let { user } = useAuth();
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);



  // const handleChange = (event) => {

  //   // console.log(event.target.value)

  //   let tmp = event.target.value; 

  //   setO(tmp.O);
  //   setC(tmp.C);
  //   setE(tmp.E);
  //   setA(tmp.A);
  //   setN(tmp.N);

  // };

    

  // let allData;
  const getAllData = async () => {

  const response = await axios.get("https://mindescape.herokuapp.com/api/me/C/1?id="+user._id);
  let allData2 = response.data;
   
    // var col = [];
 
    let row = [];
    let rowM = [];
    var row3 = [];

    let keys = allData2[0].keys

        // console.log(allData2[0]);

        let keysUsed = allData2[0].keysUsed

        // console.log(keys);

        // console.log(keysUsed);

        setKeys(keys.length);

        setKeysUsed(keysUsed.length);

let index = 1;

        for (var k in keys) {

      // console.log(k);

      var dict = {};
      var dictM = {};
      var dictionary3 = {};

      console.log(keys[k]);

      let kid = keys[k]

      const responseK = await axios.get("https://mindescape.herokuapp.com/api/me/Keys/" + kid);

      const allDataK = responseK.data;

      console.log(allDataK);


      // if( allData[i].company != null){
      // dict["id"]= Number(i)+1;
      // dict["message"]= allData[i].message;
      // dict["date"]= allData[i].dateCreated;
      // row.push(dict);  

      dictionary3["id"] = index;
      dictionary3["code"] = allDataK.code;
      dictionary3["dateCreated"] =   allDataK.dateCreated;

      index ++;

      row3.push(dictionary3);

    // }else{
    //     dictM["positions"]= allData[i].positionName;
    //     dictM["O"]= allData[i].O 
    //     dictM["C"]= allData[i].C 
    //     dictM["E"]=  allData[i].E 
    //     dictM["A"]=  allData[i].A 
    //     dictM["N"]=  allData[i].N;

    //   }
     
      // rowM.push(dictM);
    }

    // // console.log(row);
    // if( row != undefined){
    // setTRows(row);

    if( row3 != undefined){
      // console.log(row);
      setTRows3(row3);
      // rows = row;
      }
    

    // setMRows(rowM);
  
 
  };

  // function Popup(props) {
  //   return (
  //     <div>
  //       <h1>Add Job Position</h1>
  //       <form>
  //         {/* form fields */}
  //       </form>
  //       <button onClick={props.closePopup}>Close</button>
  //     </div>
  //   );
  // }


    // const CustomActions = () => (
    //     <Button variant="contained" color="primary" >
    //       Add new
    //     </Button>
    //   );
  // const [Τrows, setTRows] = React.useState({});
  // var dictionary = {};

  // let rows= [];

  // dictionary["id"]=25
  // dictionary["positions"]="Engineer"
  // dictionary["scores"]="20-35-10-25-7"

  // rows.push(dictionary)

  // dictionary={}
  // dictionary["id"]=2
  // dictionary["positions"]="Project Manager"
  // dictionary["scores"]="10-25-30-15-5"

  // const [Τcols, setCols] = React.useState([]);
  //rows.push(dictionary)
  // rows.push(dictionary)

  React.useEffect(() => {
    getAllData();
  }, []);
  
  const columns = [
    { field: 'date', headerName: 'date', width: 150 },
    { field: 'message', headerName: 'message', width: 250 },
    // { field: 'scores', headerName: 'O - C - E - A - N', width: 300 }
  ];
  // const rows = [   
    // console.log(Τrows);
    const columns3 = [
      { field: 'id', headerName: 'ID', width: 50 },
      { field: 'code', headerName: 'Code', width: 200 },
      // { field: 'manager', headerName: 'SECTOR', width: 150 },
      { field: 'dateCreated', headerName: 'dateCreated', width: 200 },    
      // { field: 'O', headerName: 'O', width: 100 },
      // { field: 'C', headerName: 'C', width: 100 },
      // { field: 'E', headerName: 'E', width: 100 },
      // { field: 'A', headerName: 'A', width: 100 },
      // { field: 'N', headerName: 'N', width: 100 },
      // { field: 'FIT', headerName: 'FIT', width: 250 },
      // { field: 'dateGiven', headerName: 'dateGiven', width: 200 },  
     ];
  // console.log(row)
  return (
    <>
    <Typography variant="h5" component="div" color="secondary">
    {keys} Availiable Keys /  {keysUsed} Used
           
           {/* <Button variant="outlined" color="secondary" style={{color: "#832221"}} onClick={handleShow}>+</Button> */}
    
        </Typography>
      <div style={{ height: 270,paddingTop: 10, display: "flex", justifyContent: "center", overflow: "hidden"}}>
        {/* { Trows != undefined && */}

       {/* { Τrows != undefined && */}
         {/* <DataGrid
          sx={{
            boxShadow: 2,
            display: "flex",
            justifyContent: "center",
          }}
          rows={Τrows}
          columns={columns}
          headerAlign="center"
          pageSize={3}
          color='secondary'
          rowsPerPageOptions={[3]}
          // checkboxSelection
        />  */}

<DataGrid
          sx={{
            boxShadow: 2,
            display: "flex",
            justifyContent: "center",
          }}
          rows={Τrows3}
          columns={columns3}
          headerAlign="center"
          pageSize={25}
          color='secondary'
          rowsPerPageOptions={[25]}
         
          // checkboxSelection
        />


{/* } */}
        </div>
{/* 
        <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Positions</TableCell>
          <TableCell>OCEAN</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Τrows.map((row) => (
          <TableRow key={row.id}>
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.positions}</TableCell>
            <TableCell>{row.scores}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      </Table>  */}
      {/* <TableFooter> */}
        {/* <TableRow> */}
          {/* <TableCell colSpan={3} align="center"> */}
              {/* <Button variant="text" color="secondary" >Edit</Button> */}
              {/* <Button variant="outlined" color="secondary" style={{color: "#832221"}} >Add Job Position</Button> */}
             
             
          {/* </TableCell> */}
        {/* </TableRow> */}
      {/* </TableFooter> */}
  
            
        {/* } */}
      
     
     
      {/* <Table columns={columns} rows={Τrows} />{" "} */}
    </>
  );
}
