/* eslint-disable react/prop-types */
// @mui material components
import Icon from "@mui/material/Icon";
import React from "react";
// Mind Escape Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiProgress from "components/SuiProgress";
// import MyStatsTableData2 from "layouts/tables/data/MyStatsTableData2";
import axios from "axios";
import Table from "examples/Table";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
// Mind Escape Dashboard React example components
import { Card, CardContent, Typography } from '@material-ui/core';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/Table";
import Button from '@mui/material/Button';
// Custom styles for the Tables
import styles from "layouts/Stats/styles";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
// import PolarChart from "examples/Charts/PolarChart";
import RadarChart from "examples/Charts/RadarChart";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Grid from "@mui/material/Grid";
// import RTable from "layouts/RoomStats/data/RTable";
import Box from '@mui/material/Box';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import MiniStatisticsCard2 from "examples/Cards/StatisticsCards 2/MiniStatisticsCard";
import { fontWeight } from "@mui/system";
import { BloodtypeOutlined } from "@mui/icons-material";


// function getAllData() {
//   const [datas, getDatas] = React.useState([]);

//   React.useEffect(() => {
//     fetch("http://127.0.0.1:8000/api/me/Metrics/", {
//       method: "GET",
//     })
//       .then((results) => results.json())
//       .then((data) => {
//         getDatas(data);
//       })
//       .catch((error) => console.log(error));
//   }, []);

//   return datas.map((data) => {
//     console.log(data);
//     // Id: "Spotift",
//     // Room: (
//     //   <SuiTypography variant="button" textColor="text" fontWeight="medium">
//     //     $2,500
//     //   </SuiTypography>
//     // ),
//     // Time: (
//     //   <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//     //     working
//     //   </SuiTypography>
//     // ),
//     // Completion: <Completion value={60} color="info" />,
//     // action,
//   });
// }



function Completion({ value, color }) {
  return (
    <SuiBox display="flex" alignItems="center" padding='1rem'>
      <SuiTypography variant="caption" textColor="text" fontWeight="medium">
        {value}%&nbsp;
      </SuiTypography>
      <SuiBox width="8rem">
        <SuiProgress value={value * 1} color={color} gradient noLabel />
      </SuiBox>
    </SuiBox>
  );
}
function getButton(user) {
  return (   
  <Link to={{ pathname: '/roomstatistics', search: `employee=${user}` }}> 
   <Button>VIEW MORE</Button>
  </Link>
  );
}

export default function RTable(props) {
  
  const [Τrows, setRows] = React.useState([]);
  const [Τcols, setCols] = React.useState([]);
  const [cd, setcd] = React.useState([]);
  const [Τrow2s2, setRow2s2] = React.useState([]);
  const [Τcols2, setCols2] = React.useState([]);

  const [Open, setOpen]= React.useState("0");
  const [Cons, setCons]= React.useState("0");
  const [Extr, setExtr]= React.useState("0");
  const [Agrr, setAgrr]= React.useState("0");
  const [Neur, setNeur]= React.useState("0");

  const [OpenTxt, setOpenTxt]= React.useState("no data");
  const [ConsTxt, setConsTxt]= React.useState("no data");
  const [ExtrTxt, setExtrTxt]= React.useState("no data");
  const [AgrrTxt, setAgrrTxt]= React.useState("no data");
  const [NeurTxt, setNeurTxt]= React.useState("no data");


  const [OpenColor, setOpenColor]= React.useState("dark");
  const [ConsColor, setConsColor]= React.useState("dark");
  const [ExtrColor, setExtrColor]= React.useState("dark");
  const [AgrrColor, setAgrrColor]= React.useState("dark");
  const [NeurColor, setNeurColor]= React.useState("dark");

  
  const [OpenC, setOpenC]= React.useState("");
  const [ConsC, setConsC]= React.useState("");
  const [ExtrC, setExtrC]= React.useState("");
  const [AgrrC, setAgrrC]= React.useState("");
  const [NeurC, setNeurC]= React.useState("");

    
  const [OpenC2, setOpenC2]= React.useState("");
  const [ConsC2, setConsC2]= React.useState("");
  const [ExtrC2, setExtrC2]= React.useState("");
  const [AgrrC2, setAgrrC2]= React.useState("");
  const [NeurC2, setNeurC2]= React.useState("");

  const [OColor, setOColor]= React.useState("grey");  
  const [CColor, setCColor]= React.useState("grey");  
  const [EColor, setEColor]= React.useState("grey");  
  const [AColor, setAColor]= React.useState("grey");  
  const [NColor, setNColor]= React.useState("grey");

  const [B1, setB1]= React.useState("0");
  const [B1Color, setB1Color]= React.useState("dark");

  const [B2, setB2]= React.useState("0");
  const [B2Color, setB2Color]= React.useState("dark");

  const [B3, setB3]= React.useState("0");
  const [B3Color, setB3Color]= React.useState("dark");

  const [B4, setB4]= React.useState("0");
  const [B4Color, setB4Color]= React.useState("dark");

  const [B5, setB5]= React.useState("0");
  const [B5Color, setB5Color]= React.useState("dark");

  const [B6, setB6]= React.useState("0");
  const [B6Color, setB6Color]= React.useState("dark");

  const [B7, setB7]= React.useState("0");
  const [B7Color, setB7Color]= React.useState("dark");

  const [B8, setB8]= React.useState("0");
  const [B8Color, setB8Color]= React.useState("dark");
  // let Open2 = "0";

  const [value, setValue] = React.useState('1');
  const classes = styles();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange2 = (event, newValue1, newValue2) => {

    // console.log(newValue1);
    setValue(newValue2);

    var row2 = [];
    var col2 = [];

    var dictionary2 = {};
    // var datum = allData;

    // dictionary2["Statistics"] = "Time";
    // dictionary2["Value"] = 8 + " min";
    // dictionary2["Notes"] = "Escaped";

    // row2.push(dictionary2);
    // dictionary2 = {};

    dictionary2["Statistics"] = "Exploring environment";
    // dictionary2["Value"] = <Completion value={newValue1.exploration} color="success" />;

    if( newValue1.exploration*10 >= 50){
      dictionary2["Notes"] = "High";
      dictionary2["Value"] = <Completion value={newValue1.exploration*10} color="success" />;
          }else if (newValue1.exploration == 0 ){
            dictionary2["Notes"] = "No data";
            dictionary2["Value"] = <Completion value={newValue1.exploration*10} color="dark" />;
          }else{
            dictionary2["Notes"] = "Low";
            dictionary2["Value"] = <Completion value={newValue1.exploration*10} color="secondary" />;
          }
    

    row2.push(dictionary2);
    dictionary2 = {};

    dictionary2["Statistics"] = "Impatience";
  
    // console.log(newValue1.impatience);
    // console.log((newValue1.impatience/7));

     if( (newValue1.impatience/7)*100 >= 50){
      dictionary2["Notes"] = "High";
      dictionary2["Value"] = <Completion value={((newValue1.impatience/7)*100).toFixed(2)} color="success" />;
          }else if (newValue1.impatience == 0 ){
            dictionary2["Notes"] = "No data";
            dictionary2["Value"] = <Completion value={((newValue1.impatience/7)*100).toFixed(2)} color="dark" />;
          }else{
            dictionary2["Notes"] = "Low";
            dictionary2["Value"] = <Completion value={((newValue1.impatience/7)*100).toFixed(2)} color="secondary" />;
          }

    row2.push(dictionary2);
    dictionary2 = {};

    dictionary2["Statistics"] = "Pushing";
    // dictionary2["Value"] = <Completion value={newValue1.pushaction} color="secondary" />;
    
    if( newValue1.pushaction >= 50){
      dictionary2["Notes"] = "High";
      dictionary2["Value"] = <Completion value={newValue1.pushaction} color="success" />;
          }else if (newValue1.pushaction == 0 ){
            dictionary2["Notes"] = "No data";
            dictionary2["Value"] = <Completion value={newValue1.pushaction} color="dark" />;
          }else{
            dictionary2["Notes"] = "Low";
            dictionary2["Value"] = <Completion value={newValue1.pushaction} color="secondary" />;
          }

    row2.push(dictionary2);
    dictionary2 = {};

    dictionary2["Statistics"] = "Leadership";
    // dictionary2["Value"] = <Completion value={newValue1.leadership} color="success" />;
    
    if( newValue1.leadership/6*100 >= 50){
      dictionary2["Notes"] = "High";
      dictionary2["Value"] = <Completion value={(newValue1.leadership/6*100).toFixed(2)} color="success" />;
          }else if (newValue1.leadership == 0 ){
            dictionary2["Notes"] = "No data";
            dictionary2["Value"] = <Completion value={(newValue1.leadership/6*100).toFixed(2)} color="dark" />;
          }else{
            dictionary2["Notes"] = "Low";
            dictionary2["Value"] = <Completion value={(newValue1.leadership/6*100).toFixed(2)} color="secondary" />;
          }

    row2.push(dictionary2);
    dictionary2 = {};

    dictionary2["Statistics"] = "Communication";
    // dictionary2["Value"] = <Completion value={newValue1.correctindicationnum} color="success" />;
    
    if( newValue1.gesture >= 50){
      dictionary2["Notes"] = "High";
      dictionary2["Value"] = <Completion value={newValue1.gesture} color="success" />;
          }else if (newValue1.gesture == 0 ){
            dictionary2["Notes"] = "No data";
            dictionary2["Value"] = <Completion value={newValue1.gesture} color="dark" />;
          }else{
            dictionary2["Notes"] = "Low";
            dictionary2["Value"] = <Completion value={newValue1.gesture} color="secondary" />;
          }

    row2.push(dictionary2);
    dictionary2 = {};

    

    dictionary2["Statistics"] = "Right Preference";
    // dictionary2["Value"] = <Completion value={newValue1.rightpref} color="info" />;
    
    if( newValue1.rightpref/6 >= 50){
      dictionary2["Notes"] = "High";
      dictionary2["Value"] = <Completion value={(newValue1.rightpref/6).toFixed(2)} color="success" />;
          }else if (newValue1.rightpref == 0 ){
            dictionary2["Notes"] = "No data";
            dictionary2["Value"] = <Completion value={(newValue1.rightpref/6).toFixed(2)} color="dark" />;
          }else{
            dictionary2["Notes"] = "Low";
            dictionary2["Value"] = <Completion value={(newValue1.rightpref/6).toFixed(2)} color="secondary" />;
          }

    row2.push(dictionary2);
    dictionary2 = {};

  

    dictionary2["Statistics"] = "Walking speed";
    // dictionary2["Value"] = <Completion value={newValue1.walkspeed} color="secondary" />;
    
    if( newValue1.walkspeed/7.5*100 >= 50){
      dictionary2["Notes"] = "High";
      dictionary2["Value"] = <Completion value={(newValue1.walkspeed/7.5*100).toFixed(2)} color="success" />;
          }else if (newValue1.walkspeed == 0 ){
            dictionary2["Notes"] = "No data";
            dictionary2["Value"] = <Completion value={(newValue1.walkspeed/7.5*100).toFixed(2)} color="dark" />;
          }else{
            dictionary2["Notes"] = "Low";
            dictionary2["Value"] = <Completion value={(newValue1.walkspeed/7.5*100).toFixed(2)} color="secondary" />;
          }

    row2.push(dictionary2);
    dictionary2 = {};

    dictionary2["Statistics"] = "Panic";
    // dictionary2["Value"] = <Completion value={newValue1.panic} color="secondary" />;
    
    if( newValue1.panic/7.5*100 >= 50){
      dictionary2["Notes"] = "High";
      dictionary2["Value"] = <Completion value={(newValue1.panic/7.5*100).toFixed(2)} color="success" />;
          }else if (newValue1.panic == 0 ){
            dictionary2["Notes"] = "No data";
            dictionary2["Value"] = <Completion value={(newValue1.panic/7.5*100).toFixed(2)} color="dark" />;
          }else{
            dictionary2["Notes"] = "Low";
            dictionary2["Value"] = <Completion value={(newValue1.panic/7.5*100).toFixed(2)} color="secondary" />;
          }

    row2.push(dictionary2);


   

    setRow2s2(row2);

    //console.log(row2);

    var dc = {};
    dc["name"] = "Statistics";
    dc["align"] = "left";

    col2.push(dc);

    dc = {};
    dc["name"] = "Value";
    dc["align"] = "left";

    col2.push(dc);

    dc = {};
    dc["name"] = "Notes";
    dc["align"] = "right";

    col2.push(dc);

    setCols2(col2);

  };

  const userS = props.user;
  let arr = [];
  const history = useHistory();
  const getAllData = async () => {
    // const response = await axios.get("https://mindescape.herokuapp.com/api/me/Metrics");

    const response2 = await axios.get("https://mindescape.herokuapp.com/api/me/M/1?username="+userS);
    // M/1?username=MBAy1rhjce1cl
    const allData = response2.data;

    const allDataO = [];
    const allDataC = [];
    const allDataE = [];
    const allDataA = [];
    const allDataN = [];

    console.log(allData)

    var row = [];
    var col = [];
    

    for (var i in allData) {
      var dictionary = {};

      var datum = allData[i];

      switch(datum.room) {
        case "O":
          allDataO.push(datum);
          break;
          case "C":
            allDataC.push(datum);
            break;
            case "E":
              allDataE.push(datum);
          break;
          case "A":
            allDataA.push(datum);
          break;
          case "N":
            allDataN.push(datum);
          break;
      }
  
      // const action = (
       
      //   <Button
      //     className="font-bold text-secondary cursor-pointer"
      //     onClick={() => history.push({
      //       pathname: '/roomstatistics',
      //       state: { data: datum }
      //     })}
      //   >
      //     Read More
      //   </Button>
        
      // );
      
      // dictionary["Id"] = datum.id;
      // if( datum.user == userS){
      dictionary["Room"] = (
        <SuiTypography variant="button" textColor="text" fontWeight="medium">
         <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          {datum.room}
        </SuiTypography>
      );

      // console.log(datum.room)

      if( datum.room == "O"){

      
        // console.log("OK O")

        setOpenColor("info");
        setB1Color("info");

        setOColor("info");
      }
      
      if( datum.room == "C"){

        // console.log("OK C")

        setConsColor("info");
        setB2Color("info");
        setB3Color("info");

        setCColor("info");
      }
      
      if( datum.room == "E"){

        // console.log("OK E")

        setExtrColor("info");
        setB4Color("info");
        setB5Color("info");

        setEColor("info");
      }
      
      if( datum.room == "A"){

        // console.log("OK A")

        setAgrrColor("info");
        setB6Color("info");
        setB7Color("info");

        setAColor("info");
      }
      
      if( datum.room == "N"){

        // console.log("OK N")

        setNeurColor("info");
        setB8Color("info");

        setNColor("info");
      }


      dictionary["Time"] = (datum.timetosolve/60).toFixed() + " min"

      let tmpstr = String(datum.date).split('T');
      dictionary["Date"] = tmpstr[0];

      // console.log(datum.completion);

       if( datum.completion == 0){
        datum.completion = 0
        dictionary["Completion"] = <Completion value={datum.completion} color="danger" />;
      }else if( datum.completion == 99.99 || datum.completion == 100 || datum.completion.toString().includes("98")  || datum.completion.toString().includes("99") ){
        datum.completion = 100
        dictionary["Completion"] = <Completion value={datum.completion} color="success" />;
      }else {
        dictionary["Completion"] = <Completion value={datum.completion} color="info" />;
      }

      // dictionary["Completion"] = <Completion value={datum.completion} color="info" />;

// dictionary["action"] = handleChange2(datum ,2);
      //dictionary["action"] = <Button onClick={(e) =>handleChange2(e,datum ,"3")}>VIEW MORE</Button>  
      // getButton(userS)

      row.push(dictionary);
      // }

    }
    setRows(row);
    // console.log(row);

    dc = {};
    dc["name"] = "Room";
    dc["align"] = "LEFT";

    col.push(dc);

    var dc = {};
    dc["name"] = "Date";
    dc["align"] = "left";

    col.push(dc);

    dc = {};
    dc["name"] = "Time";
    dc["align"] = "left";

    col.push(dc);      

    dc = {};
    dc["name"] = "Completion";
    dc["align"] = "center";

    col.push(dc);

    // dc = {};
    // dc["name"] = "action";
    // dc["align"] = "center";

    // col.push(dc);
    
   // console.log( allData);
    // // console.log( allData);    
    // console.log( allDataO);
    // console.log( allDataC);
    // console.log( allDataE);
    // console.log( allDataA);
    // console.log( allDataN);

    allData.sort((a,b) => a.id - b.id);
    allDataO.sort((a,b) => a.id - b.id);
    allDataC.sort((a,b) => a.id - b.id);
    allDataE.sort((a,b) => a.id - b.id);
    allDataA.sort((a,b) => a.id - b.id);
    allDataN.sort((a,b) => a.id - b.id);

    // console.log( allData);    
    // console.log( allDataO[0]);
    // console.log( allDataC[0]);
    // console.log( allDataE);
    // console.log( allDataA);
    // console.log( allDataN[0]);

    var O,C,E,A,N;

    var O1,O2,O3,O4,O5;
    var C1,C2,C3,C4,C5;
    var E1,E2,E3,E4,E5;
    var A1,A2,A3,A4,A5;
    var N1,N2,N3,N4,N5;

    console.log(allDataO);
    console.log(allDataC);
    console.log(allDataE);
    console.log(allDataA);
    console.log(allDataN);

    if(allDataO[0] != undefined){
      O1 = allDataO[0].O;
      C1= allDataO[0].C;
      E1= allDataO[0].E;
      A1= allDataO[0].A;
      N1= allDataO[0].N;
    }else{
      O1 = 0;
      C1= 0;
      E1= 0;
      A1= 0;
      N1= 0;
    }

    if(allDataC[0] != undefined){
      O2 = allDataC[0].O;
      C2= allDataC[0].C;
      E2= allDataC[0].E;
      A2= allDataC[0].A;
      N2= allDataC[0].N;
    }else{
      O2 = 0;
      C2= 0;
      E2= 0;
      A2= 0;
      N2= 0;
    }

    if(allDataE[0] != undefined){
      O3 = allDataE[0].O;
      C3= allDataE[0].C;
      E3= allDataE[0].E;
      A3= allDataE[0].A;
      N3= allDataE[0].N;
    }else{
      O3 = 0;
      C3= 0;
      E3= 0;
      A3= 0;
      N3= 0;
    }

    if(allDataA[0] != undefined){
      O4 = allDataA[0].O;
      C4= allDataA[0].C;
      E4= allDataA[0].E;
      A4= allDataA[0].A;
      N4= allDataA[0].N;
    }else{
      O4 = 0;
      C4= 0;
      E4= 0;
      A4= 0;
      N4= 0;
    }

    if(allDataN[0] != undefined){
      O5 = allDataN[0].O;
      C5= allDataN[0].C;
      E5= allDataN[0].E;
      A5= allDataN[0].A;
      N5= allDataN[0].N;
    }else{
      O5 = 0;
      C5= 0;
      E5= 0;
      A5= 0;
      N5= 0;
    }
    

    if(O2 > 1000){
      O2 =1
    }

    if(O5 > 1000){
     O5 = 1
    }

      
    if( O1 >= 50){
      O1 = O1 / 10
     }

    // if(allDataC[0].O > 1000){
    //   allDataC[0].O =0
    // }

    // if(allDataN[0].O > 1000){
    //   allDataN[0].O = 0
    // }
    O = (((O1/8)*0.8 + (O2/8)*0.1 + (O5/8)*0.1) * 100).toFixed();
    C = (( (Math.abs(C1)/4)*0.3 + ( Math.abs(C2)/4)*0.6 + ( Math.abs(C5)/4)*0.1) * 100).toFixed();
    E = ((( E1/12)*0.3 + ( E2/12)*0.6 + (E5/12)*0.1) * 100).toFixed();
    A = (( (Math.abs(A1)/6)*0.3 + (Math.abs(A2)/6)*0.6 + (Math.abs(C2)/6)*0.1) * 100).toFixed();
    N = ((( N1/7)*0.3 + ( N2/7)*0.6 + (N5/7)*0.1) * 100).toFixed();


   

    
    if( C >= 1000){
      C = C / 100
     }

    
    // if( N > 100){
    //   N = N / 10
    // }

    if( N >= 1000){
      N = N / 100
    }

    
    if( A >= 1000){
      A = A / 100
    }

    
    if( O >= 100){
      O = O / 10
    }

    if( C >= 100){
     C = C / 10
    }

    if( A >= 100){
      A =A / 10
     }

     console.log(O + " - " + C+ " - " + E+ " - " + A+ " - " + N);
 

    // setCols(col);

    arr = []

    // setOpen((allData[0].O.toString()/8)*100);
    setOpen(O)

    // setCons((allData[0].C/4)*100);
    setCons(C);

    // setExtr((allData[0].E/9)*100);
    setExtr(E);

    // setAgrr((allData[0].A/3)*100);
    setAgrr(A);

    // setNeur((allData[0].N/7)*100);
    setNeur(N);

    // console.log("------------");
    // console.log(allData[0].O/8);
    // console.log(allData[0].C.toString()/4);
    // console.log((allData[0].E/12));
    // console.log(allData[0].A.toString()/6);
    // console.log((allData[0].N.toString()/7));
    // console.log("------------");

    if(O >= 50  ){
      setOpenTxt("High");
      setOpenC("Inventive & Curious");
      setOpenC2("Has a love of learning, enjoys the arts, engages in a creative career or hobby, and likes meeting new people. It contribute to one’s likelihood of obtaining a leadership position, likely due to the ability to entertain new ideas and think outside the box. Openness to experience leads to gains in knowledge and skills, and it naturally increases as a person ages and has more experiences to learn from.");
    }
    // else if (allData[0].O.toString()/30*100 < 60 && allData[0].O.toString()/30*100 > 40 ){
    //   setOpenTxt("medium");
    // }
    else if (O <50 && O !=0) {
      setOpenTxt("Low");
      setOpenC("Consistent & Cautious");
      setOpenC2("Probably prefers routine over variety, sticks to what he or she knows, and prefers less abstract arts and entertainment. They are uncomfortable with change and trying new things, so they prefer the familiar over the unknown. As they are practical people, they often find it difficult to think creatively or abstractly.");
    }

    
    if(C >= 50  ){
      setConsTxt("High");
      setConsC("Efficient & Organised");
      setConsC2("Likely to be successful in school and in their careers, to excel in leadership positions, and to doggedly pursue their goals with determination and forethought. Can be commited, responsible and have proficient performance and also likely to value order, duty, achievement, and self-discipline, and they consciously practice deliberation and work toward increased competence.");
    }
    // else if (allData[0].C.toString()/30*100 < 60 && allData[0].C.toString()/30*100 > 40 ){
    //   setConsTxt("medium");
    // }
    else if (C <50 && C !=0){
      setConsTxt("low");
      setConsC("Easy-goind & careless");
      setConsC2("Much more likely to procrastinate and to be flighty, impetuous, and impulsive. may struggle with impulse control, leading to difficulty in completing tasks and fulfilling goals. They tend to be more disorganized and may dislike too much structure. They may also engage in more impulsive and careless behavior.");
    }

       
    if(E >= 50  ){
      setExtrTxt("High");
      setExtrC("Outgoing & Energetic");
      setExtrC2("Tend to seek out opportunities for social interaction, where they are often the “life of the party.” They are comfortable with others, are gregarious, and are prone to action rather than contemplation. Naturally predisposed to be more sensitive to and motivated by rewards at work, and by status recognition.");
    }
    // else if (allData[0].E.toString()/30*100 < 60 && allData[0].E.toString()/30*100 > 40 ){
    //   setExtrTxt("medium");
    // }
    else if (E <50 && E !=0){
      setExtrTxt("low");
      setExtrC("Solitary and Reserved");
      setExtrC2("Likely to be people “of few words who are quiet, introspective, reserved, and thoughtful. Tend to be more reserved and quieter. They prefer listening to others rather than needing to be heard.      Introverts often need periods of solitude in order to regain energy as attending social events can be very tiring for them. Of importance to note is that introverts do not necessarily dislike social events, but instead find them tiring.");
    }

    if(A >= 50  ){
      setAgrrTxt("High");
      setAgrrC("Friendly & Compassionate");
      setAgrrC2("Tend to be well-liked, respected, and sensitive to the needs of others. They likely have few enemies and are affectionate to their friends and loved ones, as well as sympathetic to the plights of strangers. Their characteristics make them more likable by other team members, and, as a result, easier to work with. Agreeable people can help add to a positive organizational culture and help bring out other people’s potential. ");
    }
    // else if (allData[0].A.toString()/30*100 < 60 && allData[0].A.toString()/30*100 > 40 ){
    //   setAgrrTxt("medium");
    // }
    else if (A <50 && A !=0){
      setAgrrTxt("low");
      setAgrrC("Challenging & Detached");
      setAgrrC2("Less likely to be trusted and liked by others. They tend to be callous, blunt, rude, ill-tempered, antagonistic, and sarcastic. Although not all people who are low in agreeableness are cruel or abrasive, they are not likely to leave others with a warm fuzzy feeling. May be perceived as suspicious, manipulative, and uncooperative. They may be antagonistic when interacting with others, making them less likely to be well-liked and trusted.");
    }

    if(N >= 50  ){
      setNeurTxt("High");
      setNeurC("Sensitive & Nervous");
      setNeurC2("Generally prone to anxiety, sadness, worry, and low self-esteem. They may be temperamental or easily angered, and they tend to be self-conscious and unsure of themselves. They feel anxious, insecure and self-pitying. They are often perceived as moody and irritable. They are prone to excessive sadness and low self-esteem.");
    }
    // else if (allData[0].N.toString()/30*100 < 60 && allData[0].N.toString()/30*100 > 40 ){
    //   setNeurTxt("medium");
    // }
    else if (N  <50 && N !=0){
      setNeurTxt("low");
      setNeurC("Secure & Confident");
      setNeurC2("More likely to feel confident, sure of themselves, and adventurous. They may also be brave and unencumbered by worry or self-doubt.");
    }



//     setCons(allData[0].C.toString());
// setExtr(allData[0].E.toString());
// setAgrr(allData[0].A.toString());
// // setNeur(allData[0].N.toString());

let ob1 = 0;
let ob2 = 0;
let ob3 = 0;
let ob4 = 0;
let ob5 = 0;
let ob6 = 0;
let ob7 = 0;
let ob8 = 0;
let ob9 = 0;

let cb1 = 0;
let cb2 = 0;
let cb3 = 0;
let cb4 = 0;
let cb5 = 0;
let cb6 = 0;
let cb7 = 0;

let nb1 = 0;
let nb2 = 0;
let nb3 = 0;
let nb4 = 0;
let nb5 = 0;
let nb6 = 0;

if( allDataO[0] != undefined){

 ob1= allDataO[0].actionsnum
 ob2= allDataO[0].correctactionsnum
  ob3= allDataO[0].impatience
  ob4= allDataO[0].walkspeed
  ob5= allDataO[0].leadership
  ob6= allDataO[0].right
  ob7= allDataO[0].run
  ob8= allDataO[0].timetosolve

}



if( allDataC[0]!= undefined){
 cb1 = allDataC[0].exploration
 cb2 = allDataC[0].impatience
 cb3 = allDataC[0].walkspeed
 cb4 = allDataC[0].leadership
 cb5 = allDataC[0].right
 cb6 = allDataC[0].timetosolve
 cb7 = allDataC[0].run
}


if( allDataN[0] != undefined){
 nb1 = allDataN[0].impatience
 nb2 = allDataN[0].walkspeed
 nb3 = allDataN[0].leadership
 nb4 = allDataN[0].right
 nb5 = allDataN[0].timetosolve
 nb6 = allDataN[0].run
}


let be1 =  (ob1/ob2)*8 + cb1 
let be2 =  ob3/11*0.2 + cb2/11*0.6 + nb1/11*0.2
let be3 =  ob4/7.5*0.2 + cb3/7.5*0.6 + nb2/7.5*0.2
let be4 = ob5/6*0.6 + cb4/6*0.2 + nb3/6*0.2
let be7 = ob6/20*0.3 + cb4/20*0.3 + nb4/20*0.3
let be8 = (ob7/(600-ob8))*0.3 + (cb7/(600-cb6))*0.3 +
 (nb6/(600-nb5))*0.3


console.log("*****");

if( be1>100){
  be1 = be1 - ( be1-100)
}

if( be7 < 1){
  be7 = 1 / be7
}

console.log(be1);
console.log(be2*100);
console.log(be3*100);
console.log(be4*100);
console.log(allData[0].indicationnum);
console.log(allData[0].pushaction);
console.log(be7);
console.log(be8*100);
// setB1(allData[0].exploration*10);
// setB2((allData[0].impatience/7)*100);
// setB3(allData[0].walkspeed/7.5*100);
// setB4(allData[0].leadership/6*100);
// setB5(allData[0].indicationnum);
// setB6(allData[0].pushaction);
// setB7(allData[0].rightpref/6);
// setB8(allData[0].panic/7.5*100);

setB1(be1);
setB2(be2*100);
setB3(be3*100);
setB4(be4*100);
setB5(allData[0].indicationnum);
setB6(allData[0].pushaction);
setB7(be7);
setB8(be8*100);

//     console.log("------------");
// console.log(allData[0].exploration*10);
// console.log((allData[0].impatience/7)*100);
// console.log(allData[0].walkspeed/7.5*100);
// console.log(allData[0].leadership/6*100);
// console.log(allData[0].indicationnum);
// console.log(allData[0].pushaction);
// console.log(allData[0].rightpref/6);
// console.log(allData[0].panic/7.5*100);
//     console.log("------------");

    // console.log( allData[0].O);

    // Open2 = allData[0].O.toString();

    // console.log(allData[0].O.toString());
    // arr.push(allData[0].O/8*100);
    // arr.push(allData[0].C/4*100);
    // arr.push(allData[0].E/9*100);
    // arr.push(allData[0].A/3*100);
    // arr.push(allData[0].N/7*100);

    arr.push(O);
    arr.push(C);
    arr.push(E);
    arr.push(A);
    arr.push(N);


    // setOpen(allData[0].O.toString()/8*100);
    // setCons(allData[0].C.toString()/4*100);
    // setExtr(allData[0].E.toString()/9*100);
    // setAgrr(allData[0].A.toString()/3*100);
    // setNeur(allData[0].N.toString()/7*100);


    setcd(arr);

    // console.log(arr);
    // console.log(cd);

  };

  React.useEffect(() => {
    getAllData();
  }, []);

  return (
    <>
  
      <Table columns={Τcols} rows={Τrows} />{" "}
     


      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Overview" value="1" />
            <Tab label="Personality" value="2" />
            {/* <Tab label="Behaviors" value="3" /> */}
          </TabList>
        </Box>

        <TabPanel  value="1"> 

        Personality
        
        <Grid container spacing={6}>
      <Grid item xs={12} sm={6} xl={2.4}>
        {/* { Open != "0" && */}
              <MiniStatisticsCard2
                title={{fontWeight:"bold", text: "Openness", text2: OpenC }}
                count={{ text: Open, color: OpenColor}}
                percentage={{ color: OpenColor, text: OpenTxt }}
                icon={{ color: OColor, component: "emoji_events", title: "O" }}
                
                
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={2.4}>
              <MiniStatisticsCard2
                title={{fontWeight:"light",text: "Conscientiousness", text2:ConsC }}
                count={{text: Cons, color: ConsColor}}
                percentage={{ color: "dark", text: ConsTxt }}
                icon={{ color: CColor, component: "emoji_events ", title: "C"  }}
                //gold
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={2.4}>
              <MiniStatisticsCard2
                title={{ fontWeight:"light",text: "Extraversion", text2:"" }}
                count={{ color: ExtrColor, text: Extr }}
                percentage={{ color: "dark", text: ExtrTxt }}
                icon={{ color: EColor, component: "emoji_events", title: "E"  }}
                // purple
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={2.4}>
              <MiniStatisticsCard2
                title={{fontWeight:"light", text: "Agreeableness", text2:"" }}
                count={{ color: AgrrColor, text: Agrr }}
                percentage={{ color: "dark", text: AgrrTxt }}
                icon={{ color: AColor, component: "emoji_events", title: "A"  }}
                // green
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={2.4}>
              <MiniStatisticsCard2
                title={{ fontWeight:"light",text: "Neuroticism", text2:NeurC }}
                count={{ color: NeurColor, text: Neur }}
                percentage={{ color: "dark", text: NeurTxt }}
                icon={{ color: NColor, component: "emoji_events", title: "N"  }}
                // red
              />
            </Grid>
            </Grid>

            Behaviors

  <Grid container spacing={5}>
            
  
      <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Exploring environment" }}              
                count={{ color: B1Color, text: B1 }}
                percentage={{ color: B1Color, text: "" }}
                icon={{ color: OColor, component: "explore" }}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Impatience" }}
                count={{ color: B2Color, text: B2 }}
                percentage={{ color:B2Color, text: "" }}
                icon={{ color: CColor, component: "hourglass_empty" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Walking speed" }}
                count={{ color: B3Color, text: B3 }}
                percentage={{ color: B3Color, text: "" }}
                icon={{ color: CColor, component: "speed" }}
              />
            </Grid>

              <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Leadership" }}
                count={{ color: B4Color, text: B4 }}
                percentage={{ color: B4Color, text: "" }}
                icon={{ color: EColor, component: "emoji_events" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Communication" }}
                count={{ color: B5Color, text: B5 }}
                percentage={{ color: B5Color, text: "" }}
                icon={{ color: EColor, component: "chat_bubble " }}
              />
            </Grid>
      
         
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Pushing" }}
                count={{ color: B6Color, text: B6 }}
                percentage={{ color: B6Color, text: "" }}
                icon={{ color: AColor, component: "pan_tool" }}
              />
            </Grid>
           

            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Right Preference" }}
                count={{ color: B7Color, text: B7 }}
                percentage={{ color: B7Color, text: "" }}
                icon={{ color: AColor, component: "directions" }}
              />
            </Grid>

            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Panic" }}
                count={{ color: B8Color, text: B8 }}
                percentage={{ color: B8Color, text: "" }}
                icon={{ color: NColor, component: "directions_run" }}
              />
            </Grid>
           
          
            </Grid>

        
        </TabPanel>


        <TabPanel value="2">
        <Grid container spacing={3} sx={{borderRadius:10}} >
          <Grid item xs={10} lg={5} sx={{borderRadius:20}} >
            {/* <GradientLineChart
              title="Sales Overview"
              description={
                <SuiBox display="flex" alignItems="center">
                  <SuiBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                    <Icon className="font-bold">arrow_upward</Icon>
                  </SuiBox>
                  <SuiTypography variant="button" textColor="text" fontWeight="medium">
                    4% more{" "}
                    <SuiTypography variant="button" textColor="text" fontWeight="regular">
                      in 2021
                    </SuiTypography>
                  </SuiTypography>
                </SuiBox>
              }
              height="20.25rem"
              chart={gradientLineChartData}
            /> */}

            <RadarChart
              title="Radar Chart"
              chart={{
                labels: ["O", "C", "E", "A", "N"],
                datasets: [
                  {
                    label: "Player",
                    color: "info",
                    data: [Open, Cons, Extr, Agrr, Neur],
                    borderDash: [5, 5],
                  },                 
                ],
              }}
            />

          </Grid>
          <Grid item xs={12} lg={7}>
         
          <Card style={{marginBottom: "1rem", borderRadius:20 }} >
  <CardContent>
    <Typography variant="h5" gutterBottom>
      Openness
    </Typography>

    <Typography variant="subtitle2" gutterBottom>
    {OpenC} 
    </Typography>

    <Typography variant="body1" gutterBottom>
    {OpenC2}
    </Typography>
  </CardContent>
</Card>
<Card style={{marginBottom: "1rem", borderRadius:20 }} >
  <CardContent>
  <Typography variant="h5" gutterBottom>
      Conscientiousness
    </Typography>

    <Typography variant="subtitle2" gutterBottom>
     {ConsC}
    </Typography>

    <Typography variant="body1" gutterBottom>
    {ConsC2}    </Typography>
  </CardContent>
</Card>
<Card style={{marginBottom: "1rem", borderRadius:20 }} >
  <CardContent>
    <Typography variant="h5" gutterBottom>
      Extroversion
    </Typography>

    <Typography variant="subtitle2" gutterBottom>
     {ExtrC}
    </Typography>

    <Typography variant="body1" gutterBottom>
    {ExtrC2}  </Typography>
  </CardContent>
</Card>

<Card style={{marginBottom: "1rem", borderRadius:20 }} >
  <CardContent>
    <Typography variant="h5" gutterBottom>
 Agreeableness
    </Typography>

    <Typography variant="subtitle2" gutterBottom>
    {AgrrC}
    </Typography>

    <Typography variant="body1" gutterBottom>
    {AgrrC2}  </Typography>
  </CardContent>
</Card>

<Card style={{marginBottom: "1rem", borderRadius:20 }} >
  <CardContent>
    <Typography variant="h5" gutterBottom>
      Neuroticism
    </Typography>

    <Typography variant="subtitle2" gutterBottom>
      {NeurC}
    </Typography>

    <Typography variant="body1" gutterBottom>
    {NeurC2}  </Typography>
  </CardContent>
</Card>



          </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value="3">        
        <div>
   
          <SuiBox customClass={classes.tables_table}>
            {/* <RTable></RTable> */}
            {/* <Table columns={prCols} rows={prRows} /> */}
            { Τrow2s2!= undefined &&
            <Table columns={Τcols2} rows={Τrow2s2} />
            }
          </SuiBox>
       
  
    </div>
      </TabPanel>
      </TabContext>

      {/* <SuiTypography variant="h6">General Statistics</SuiTypography> */}
      {/* <SuiBox mb={3}> */}
        
        {/* </Grid> */}
      {/* </SuiBox> */}
   
    </>
  );
}
