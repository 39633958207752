/**
=========================================================
* Mind Escape Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import * as React from 'react';
// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
// Mind Escape Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import Button from '@mui/material/Button';
// Mind Escape Dashboard Materail-UI example components
// import Table from "examples/Table";
import Table from "./data/index.js";
import Table2 from "./data/table2"
import Table3 from "./data/table3"
import TableST from "./data/indexST"
import Table4 from "./data/table4.js";
// Custom styles for the /Employees/
import styles from "layouts/Admin/components/Employees/styles";
import MediaControlCard from './data/simpletable'
// Data
// import data from "layouts/Admin/components/Employees/data";
// import { TableCell } from "@mui/material";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useAuth } from "auth-context/auth.context";
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ProgressBar from "@ramonak/react-progress-bar";

export let c1;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);
// const getAllData = async () => {

//   const response4 = await axios.get("https://mindescape.herokuapp.com/api/me/User");
//   const allData4 = response5.data;


//   const response5 = await axios.get("https://mindescape.herokuapp.com/api/me/Company");
//   const allData5 = response5.data;

//   if(allData5 != undefined){
//     for (var j in allData5) {
//       var dict = {};
   
//       dict["id"]= allData5[j].id;
//       dict["companyName"]= allData5[j].companyName;
//       dict["dateCreated"]= allData5[j].dateCreated;
//       dict["keys"]= allData5[j].keys;
//       dict["admins"]= allData5[j].admins;
  
//       // rowM.push(dict);
  
//   };
//   }

// };





function Employees() {

  let { user } = useAuth();

  // console.log(user)
  // const { columns, rows } = data();
  const [menu, setMenu] = useState(null);
  const classes = styles();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const [c1, setC1] =useState(0);
  const [c2, setC2] =useState(0);
  const [c3, setC3] =useState(0);
  const [c4, setC4] =useState(0);
  const [call, setCall] =useState(0);
  const [keys, setKeys] =useState(0);
  const [keysUsed, setKeysUsed] =useState(0);

  const [companyname, setcompanyname] =useState("");

  let c1b =0;
let c2b= 0;
let c3b=0;
let c4b=0;
let c5 = 0;



  const getAllData = async () => {

  

      // console.log(user)


    if(user.is_superuser){

      const response4 = await axios.get("https://mindescape.herokuapp.com/api/me/Metrics");
      const allData = response4.data;
      
      
     
      // console.log(allData2)
    // console.log(response4);
    
    for (var i in allData) {
      var dictionary = {};
    
      dictionary["id"] = allData[i].id;
      dictionary["employee"] = allData[i].user;
    
      if (allData[i].user != undefined) {

      
      c1b++;  

      c5++;
        
      if( allData[i].completion > 0){
        // console.log("C" + allData[i].completion)
        c2b++;
      }else if( allData[i].completion == 0){
        // console.log("N" +allData[i].completion)
        c3b++;
      }

      if( allData[i].completion == 99.99){
        c4b++;
      }

    }
    // else{

    //   const response2 = await axios.get("https://mindescape.herokuapp.com/api/me/C/1?id="+user._id);
    //   // M/1?username=MBAy1rhjce1cl
    //   const allData2 = response2.data;

    //   console.log(allData2);

    //   let cempl = allData2[0].empl

    
    //   if ( cempl.indexOf(allData[i].id) != -1) {
    //   // if (allData[i].user.startsWith("M")) {
    //     // dictionary["manager"] = "Masoutis";
    //     c1b++;  
        
    //     if( allData[i].completion > 0){
    //       // console.log("C" + allData[i].completion)
    //       c2b++;
    //     }else if( allData[i].completion == 0){
    //       // console.log("N" +allData[i].completion)
    //       c3b++;
    //     }

    //     if( allData[i].completion == 99.99){
    //       c4b++;
    //     }
        
    //   }
    // }
  
    }
  }else{

    const response2 = await axios.get("https://mindescape.herokuapp.com/api/me/C/1?id="+user._id);
      //     // M/1?username=MBAy1rhjce1cl
          const allData2 = response2.data;
    
          // console.log(allData2);

          let cempl = allData2[0].empl

          setKeys(allData2[0].keys.length);
          setKeysUsed(allData2[0].keysUsed.length);

setcompanyname(allData2[0].companyName);

        //  console.log(allData2[0].companyName);

          var dictionary = {};
          
          var row = [];

          for (var j in cempl) {

            // console.log(cempl[j]);

            let uid = cempl[j]

            const response3 = await axios.get("https://mindescape.herokuapp.com/api/me/User/" + uid);

            // console.log(response3.data);

            let user = response3.data;

            const response2 = await axios.get("https://mindescape.herokuapp.com/api/me/M/1?username="+user.user);

          //   const response = await axios.get("https://mindescape.herokuapp.com/api/me/User/" + uid);

            // console.log(response2.data);

            const allData = response2.data;

            c1b++;  

            for (var i in allData) {

              c5 ++;
        
            if( allData[i].completion > 0){
              // console.log("C" + allData[i].completion)
              c2b++;
            }else if( allData[i].completion == 0){
              // console.log("N" +allData[i].completion)
              c3b++;
            }
      
            if( allData[i].completion == 99.99){
              c4b++;
            }

          }
      

          //   let user = response.data;

          //   dictionary["id"] = user.id;
          // dictionary["employee"] =user.user;
          // dictionary["manager"] =   allData2[0].companyName;
          // row.push(dictionary);
          }

  }
  
  // console.log(c3b);
//   if( c1b==0){
// c1b = 1;
//   }
//   if( c2b==0){
//     c2b = 1;
//       }

  setC1(c1b);
  setC2(c2b);
  setC3(c3b);
  setC4(c4b);
  setCall(c5);
  };
  
  React.useEffect(() => {
    getAllData();
  },[]);

  function randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

  function getButton2() {
    // console.log("ok")
    // console.log("ok");

   
  var rString  = randomString(5, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

    var username = "TC" + rString;
    var password = username;
    var email = username + "@realmint.ai";

    // console.log(username);
    // console.log(password);
    // console.log(email);

    const data = JSON.stringify({ "username": username, "password" : password, "email" : email });

// console.log(data);

registry(username,password,email);

// axios.post("https://mindescape.herokuapp.com/api/users/register/", data);

// location.reload();
  }

  const registry = async (username,password,email) => {

    
const res = await axios.post("https://mindescape.herokuapp.com/api/users/register/", { "username": username, "password" : password, "email" : email });

// console.log(res);

var nuid = res.data.userID

// console.log(nuid);

const response = await axios.get("https://mindescape.herokuapp.com/api/me/P/1?username=" + username);

// console.log(response);

  }

  

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (    
    <>
      <SuiBox  borderRadius="xxl" display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <SuiBox>

        
        <Item  sx={{borderRadius:6}}>  
          <SuiTypography variant="h6" gutterBottom>
          {companyname} 
          </SuiTypography>
          <SuiTypography variant="h6" gutterBottom>
                {user.username} 
          </SuiTypography>
          </Item>

       
        </SuiBox>
        {/* { companyname === "Team" && 
 <Item  sx={{borderRadius:6}}> 
    <Button onClick={e=>getButton2(e)}>Add User</Button>
          </Item>
} */}
        {/* <SuiBox color="text" px={2}>
          <Icon className="cursor-pointer font-bold" fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </SuiBox> */}
        {renderMenu}
      </SuiBox>
      {/* <SuiBox customClass={classes.Employees_table}  borderRadius="xxl"> */}
        {/* <Table columns={columns} rows={rows} /> */}
        <Box sx={{ flexGrow: 1 }}>
        { companyname !== "Team" && 
<>
      <Grid container spacing={3} sx={{paddingBottom: 2, borderRadius:10}} >

      
        <Grid item xs={5}  sx={{paddingBottom: 2, borderRadius:10}}>
        <Item  sx={{paddingBottom: 2, borderRadius:10}}>  
          <Card sx={{ minWidth: 400, minHeight: 300, borderRadius:10 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          MindEscape
        </Typography>
        <Typography variant="h5" component="div" color="primary">
          Statistics from {c1} players - {call} rooms played
          {/* <br></br> */}
          {/* {keys} keys - {keysUsed} keys used */}
        </Typography>
        <Typography sx={{ mt: 2 }} color="text.secondary">
          {bull} Completed
        </Typography>
        
  
        <ProgressBar completed={ parseInt((c2/call*100).toFixed(0)) } bgColor="#af9b2a"/>   

        <Typography sx={{ mt: 2 }} color="text.secondary">
          {bull} Escaped
        </Typography>
        <ProgressBar completed={parseInt(((c2-c3)/call * 100).toFixed(0)) } bgColor="green"/>   
        <Typography color="text.secondary" >
          {bull} Not Escaped
        </Typography>
        <ProgressBar completed={ parseInt((c3/call * 100).toFixed(0)) } bgColor="red"/>
        <br/>       
      </CardContent>
      {/* <CardActions sx={{ paddingTop: 8 }}>
        <Button size="small" onClick={() => setCompleted(50)}>Change Progress</Button>
      </CardActions> */}
    </Card>
    </Item>
        </Grid>     
           
        <Grid item xs={4} >
           <Item  sx={{paddingBottom: 2, borderRadius:10}}> <Table2/></Item>
          
        </Grid>

        <Grid item xs={3} >
           <Item  sx={{paddingBottom: 2, borderRadius:10}}> <Table4/></Item>
          
        </Grid>

      </Grid>
       <Item  sx={{paddingBottom: 2, borderRadius:10}}> 
       <Table />
       </Item>
       </>
}



{ companyname == "Team" && 
<>
<Grid container spacing={3} sx={{paddingBottom: 2, borderRadius:10}} >

      
<Grid item xs={7}  sx={{paddingBottom: 2, borderRadius:10}}>
  <Item  sx={{paddingBottom: 2, borderRadius:10}}>  
    <Card sx={{ minWidth: 400, minHeight: 300, borderRadius:10 }}>
<CardContent>
  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
    MindEscape
  </Typography>
  <Typography variant="h5" component="div" color="primary">
    Usage Statistics from {c1} players 
  </Typography>
  <Typography sx={{ mt: 2 }} color="text.secondary">
    {bull} Completed
  </Typography>
  <ProgressBar completed={ parseInt((c2/c1*100).toFixed(0)) } bgColor="#af9b2a"/>   
  <Typography sx={{ mt: 2 }} color="text.secondary">
    {bull} Escaped
  </Typography>
  <ProgressBar completed={parseInt(((c2-c3)/c2 * 100).toFixed(0)) } bgColor="green"/>   
  <Typography color="text.secondary" >
    {bull} Not Escaped
  </Typography>
  <ProgressBar completed={ parseInt((c3/c2 * 100).toFixed(0)) } bgColor="red"/>
</CardContent>

{/* <CardActions sx={{ paddingTop: 8 }}>
  <Button size="small" onClick={() => setCompleted(50)}>Change Progress</Button>
</CardActions> */}


</Card>
</Item>
</Grid> 
<Grid item xs={5} >
           <Item  sx={{paddingBottom: 2, borderRadius:10}}> <Table4/></Item>
          
        </Grid>

 </Grid>

<Item  sx={{paddingBottom: 2, borderRadius:10}}> 
<TableST />
</Item>
  </>
}





    </Box>
       

       
      
       
      {/* </SuiBox> */}
    </>
  );
}

export default Employees;
