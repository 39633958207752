/**
=========================================================
* Mind Escape Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
// import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
import { useState } from "react";
// Mind Escape Dashboard React components
// import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";
import PropTypes from "prop-types";
// Mind Escape Dashboard React example components
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
// import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import RadarChart from "examples/Charts/RadarChart";

// Mind Escape Dashboard React base styles
// import typography from "assets/theme/base/typography";

// Dashboard layout components
// import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers";
// import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets";
// import Customers from "layouts/Superuser/components/Customers";
// import OrderOverview from "layouts/Superuser/components/OrderOverview";
// import RTableReview from "layouts/Stats/data/RTableReviews";
// Data
// import reportsBarChartData from "layouts/Superuser/data/reportsBarChartData";
// import gradientLineChartData from "layouts/Superuser/data/gradientLineChartData";

import React from "react";
// import axios from "axios";

function Graph3({ datas }) {
  const [dataU] = useState(datas);
  // console.log(dataU);
  // const { size } = typography;
  // console.log(dataU.datasets.data);

  // var reportsBarChartData2 = {
  //   chart: dataU.chart,
  //   items: dataU.items,
  // };

  // console.log(reportsBarChartData2);
  // console.log(reportsBarChartData2.chart);
  // console.log(reportsBarChartData2.items);

  var chart = {
    labels: ["Very Unsatisfied", "Unsatisfied", "Neutral", "Satisfied", "Very satisfied"],
    datasets: [
      {
        label: "Openness",
        color: "dark",
        data: dataU.datasets[0].data,
        borderDash: [5, 5],
      },
    ],
  };
  // console.log(dataU.chart);
  // var reportsBarChartData = dataU.chart;

  // var items = dataU.items;

  // var thismonth = dataC.bymonths[3] - dataC.bymonths[2];

  //   React.useLayoutEffect(() => {
  //     getAllData();
  //   }, [gradientLineChartData]);

  return (
    <>
      {/* <ReportsBarChart
        title="Users"
        // description={
        //   <>
        //     (<strong>+23%</strong>) than last week
        //   </>
        // }
        chart={reportsBarChartData2.chart}
        items={reportsBarChartData2.items}
      /> */}
      <RadarChart title="Opinion" chart={chart} />
    </>
  );
}

Graph3.propTypes = {
  datas: PropTypes.array,
};

export default Graph3;
