/**
=========================================================
* Mind Escape Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Mind Escape Dashboard React components
import SuiBox from "components/SuiBox";
// import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";

// Custom styles for the WorkWithTheRockets
import styles from "layouts/dashboard/components/WorkWithTheRockets/styles";

function WorkWithTheRockets() {
  const classes = styles();

  return (
    <Card className="h-100">
      <SuiBox position="relative" height="100%" p={2}>
        <SuiBox customClass={classes.workWithTheRockets_content}>
          <SuiBox pt={1}>
            <SuiTypography variant="h5" textColor="white" fontWeight="bold">
              powered by RealMINT
            </SuiTypography>
          </SuiBox>
          <SuiBox mb={8}>
            <SuiTypography variant="body2" textColor="white">
              RealMINT is an AUTH spin off, that produce high end serious games
              with a team of proffessionals.
              <br/>
              Our purpose: <br/>
<li>Evaluate and improve player skills, 
behaviors and personality traits </li> <br/>
<li>Diagnose and manage mental
disorders </li> <br/>
<li>Simulate realistic in-game behaviors
by computer-controlled characters</li>
            </SuiTypography>
          </SuiBox>
          <SuiTypography
            component="a"
            href="https://www.realmint.ai/"
            target="_blank"
            variant="button"
            textColor="white"
            fontWeight="medium"
            customClass={classes.workWithTheRockets_button}
          >
            Find out More
            <Icon className="font-bold">arrow_forward</Icon>
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default WorkWithTheRockets;
