/**
=========================================================
* Mind Escape Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Card, CardContent, Typography } from '@material-ui/core';

// Mind Escape Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiProgress from "components/SuiProgress";
// Mind Escape Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import Table from "examples/Table";
import axios from "axios";
import React from "react";
// Custom styles for the Tables
import styles from "layouts/Stats/styles";
import Button from '@mui/material/Button';
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
// import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
// import PolarChart from "examples/Charts/PolarChart";
import RadarChart from "examples/Charts/RadarChart";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";


// Mind Escape Dashboard React base styles
// import typography from "assets/theme/base/typography";

// Data
import reportsBarChartData from "layouts/Stats/data/reportsBarChartData";
// import gradientLineChartData from "layouts/tables/data/gradientLineChartData";
import { DataGrid } from '@mui/x-data-grid';
// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import MyStatsTableData2 from "layouts/tables/data/MyStatsTableData2";
import RTable from "layouts/Stats/data/RTable";
import { useLocation } from 'react-router-dom';
import Table from "examples/Table";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';


function StatisticsPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const employee = searchParams.get('employee');

  // const columns = [
  //   { key: 'id', name: 'ID' },
  //   { key: 'employee', name: 'EMPLOYEE'}
  // ];

  // const rows = [
  //   { id: id},
  //   { employee: employee}
  // ];
  // console.log(rows)
  // console.log(columns)
  return (
   <>
    {/* <div style={{ height: 300, display: "flex", justifyContent: "center", overflow: "hidden" }}> */}
        {/* { Trows != undefined && */}
       
        <RTable user={employee}></RTable>
        {/* <Table columns={Τcols} rows={Τrows} />{" "} */}
   
          </>
  );
}

function Tables() {
  const classes = styles();
  // const { size } = typography;
  const { chart, items } = reportsBarChartData;
  // const { columns, rows } = authorsTableData;
  // const { columns: prCols, rows: prRows } = MyStatsTableData2;
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Router>
            <Switch>
              <Route path="/statistics" component={StatisticsPage} />
              {/* Other routes here */}
            </Switch>
      </Router>
  
    

      {/* <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <PolarChart
              title="Polar Chart"
              chart={{
                labels: ["Red", "Green", "Yellow", "Grey", "Blue"],
                datasets: {
                  label: "My First Dataset",
                  data: [11, 16, 7, 3, 14],
                  backgroundColors: ["info", "primary", "dark", "secondary", "success"],
                },
              }}
            />
          </Grid>
        </Grid>
      </SuiBox> */}

      {/* <SuiBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <RadarChart
              title="Radar Chart"
              chart={{
                labels: ["O", "C", "E", "A", "N"],
                datasets: [
                  {
                    label: "Student A",
                    color: "dark",
                    data: [65, 75, 70, 80, 60],
                    borderDash: [5, 5],
                  },
                  {
                    label: "Student B",
                    color: "info",
                    data: [54, 65, 60, 70, 70],
                  },
                ],
              }}
            />
          </Grid>
        </Grid>
      </SuiBox> */}
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
