// // @mui material components
// import Tooltip from "@mui/material/Tooltip";

// // Mind Escape Dashboard React components
// import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";
// import SuiAvatar from "components/SuiAvatar";
// import SuiProgress from "components/SuiProgress";

// // Custom styles for the Projects
// import styles from "layouts/dashboard/components/Projects/styles";

// // Images
// import logoXD from "assets/images/small-logos/logo-xd.svg";
// import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
// import logoSlack from "assets/images/small-logos/logo-slack.svg";
// import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
// import logoJira from "assets/images/small-logos/logo-jira.svg";
// import logoInvesion from "assets/images/small-logos/logo-invision.svg";
// import team1 from "assets/images/team-1.jpg";
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";

// export default function data() {
//   const classes = styles();

//   const avatars = (members) =>
//     members.map(([image, name]) => (
//       <Tooltip key={name} title={name} placeholder="bottom">
//         <SuiAvatar src={image} alt="name" size="xs" customClass={classes.projects_tableAvatar} />
//       </Tooltip>
//     ));

//   return {
//     columns: [
//       { name: "companies", align: "left" },
//       { name: "members", align: "left" },
//       { name: "budget", align: "center" },
//       { name: "completion", align: "center" },
//     ],

//     rows: [
//       {
//         companies: [logoXD, "Mind Escape XD Version"],
//         members: (
//           <SuiBox display="flex" py={1}>
//             {avatars([
//               [team1, "Ryan Tompson"],
//               [team2, "Romina Hadid"],
//               [team3, "Alexander Smith"],
//               [team4, "Jessica Doe"],
//             ])}
//           </SuiBox>
//         ),
//         budget: (
//           <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//             $14,000
//           </SuiTypography>
//         ),
//         completion: (
//           <SuiBox width="8rem" textAlign="left">
//             <SuiProgress value={60} color="info" gradient />
//           </SuiBox>
//         ),
//       },
//       {
//         companies: [logoAtlassian, "Add Progress Track"],
//         members: (
//           <SuiBox display="flex" py={1}>
//             {avatars([
//               [team2, "Romina Hadid"],
//               [team4, "Jessica Doe"],
//             ])}
//           </SuiBox>
//         ),
//         budget: (
//           <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//             $3,000
//           </SuiTypography>
//         ),
//         completion: (
//           <SuiBox width="8rem" textAlign="left">
//             <SuiProgress value={10} color="info" gradient />
//           </SuiBox>
//         ),
//       },
//       {
//         companies: [logoSlack, "Fix Platform Errors"],
//         members: (
//           <SuiBox display="flex" py={1}>
//             {avatars([
//               [team1, "Ryan Tompson"],
//               [team3, "Alexander Smith"],
//             ])}
//           </SuiBox>
//         ),
//         budget: (
//           <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//             Not set
//           </SuiTypography>
//         ),
//         completion: (
//           <SuiBox width="8rem" textAlign="left">
//             <SuiProgress value={100} color="success" gradient />
//           </SuiBox>
//         ),
//       },
//       {
//         companies: [logoSpotify, "Launch our Mobile App"],
//         members: (
//           <SuiBox display="flex" py={1}>
//             {avatars([
//               [team4, "Jessica Doe"],
//               [team3, "Alexander Smith"],
//               [team2, "Romina Hadid"],
//               [team1, "Ryan Tompson"],
//             ])}
//           </SuiBox>
//         ),
//         budget: (
//           <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//             $20,500
//           </SuiTypography>
//         ),
//         completion: (
//           <SuiBox width="8rem" textAlign="left">
//             <SuiProgress value={100} color="success" gradient />
//           </SuiBox>
//         ),
//       },
//       {
//         companies: [logoJira, "Add the New Pricing Page"],
//         members: (
//           <SuiBox display="flex" py={1}>
//             {avatars([[team4, "Jessica Doe"]])}
//           </SuiBox>
//         ),
//         budget: (
//           <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//             $500
//           </SuiTypography>
//         ),
//         completion: (
//           <SuiBox width="8rem" textAlign="left">
//             <SuiProgress value={25} color="info" gradient />
//           </SuiBox>
//         ),
//       },
//       {
//         companies: [logoInvesion, "Redesign New Online Shop"],
//         members: (
//           <SuiBox display="flex" py={1}>
//             {avatars([
//               [team1, "Ryan Tompson"],
//               [team4, "Jessica Doe"],
//             ])}
//           </SuiBox>
//         ),
//         budget: (
//           <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//             $2,000
//           </SuiTypography>
//         ),
//         completion: (
//           <SuiBox width="8rem" textAlign="left">
//             <SuiProgress value={40} color="info" gradient />
//           </SuiBox>
//         ),
//       },
//     ],
//   };
// }

/* eslint-disable react/prop-types */
// @mui material components
// import Icon from "@mui/material/Icon";
import React from "react";
// Mind Escape Dashboard React components
// import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";
// import SuiProgress from "components/SuiProgress";
// import MyStatsTableData2 from "layouts/tables/data/MyStatsTableData2";
import axios from "axios";
import Table from "examples/Table";
import { DataGrid } from '@mui/x-data-grid';
import SuiTypography from "components/SuiTypography";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import { useAuth } from "auth-context/auth.context";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import ProgressBar from "@ramonak/react-progress-bar";
import { FilePresent } from "@mui/icons-material";
// function Completion({ value, color }) {
//   return (
//     <SuiBox display="flex" alignItems="center">
//       <SuiTypography variant="caption" textColor="text" fontWeight="medium">
//         {value}&nbsp;
//       </SuiTypography>
//       <SuiBox width="8rem">
//         <SuiProgress value={value} color={color} gradient noLabel />
//       </SuiBox>
//     </SuiBox>
//   );
// }
export default function RTable() {
  
const [Τrows, setTRows] = React.useState([]);
const [Τrows2, setTRows2] = React.useState([]);
const [Τrows3, setTRows3] = React.useState([]);
const [keys, setkeys] = React.useState();
const [keysUsed, setkeysUsed] = React.useState();

const [loading, setLoading] = React.useState(true);

  // const [Τrows, setTRows] = React.useState({});
  let rows= [];

  let { user } = useAuth();
  // const [Τcols, setCols] = React.useState([]);
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const createKeys = async (event) => {

    const response2 = await axios.get("https://mindescape.herokuapp.com/api/me/C/1?id="+user._id);
    //     // M/1?username=MBAy1rhjce1cl
        const allData2 = response2.data;

        // console.log(allData2);

        
        // console.log(allData2[0].keys);

        let kn = 5;

        

        const today = new Date();

        const month = today.getMonth()+1;
const year = today.getFullYear();
const date = today. getDate();
const currentDate = year+"-"+month+"-"+date;

        let json = {          "code": "",          "dateCreated": currentDate,          "dateGiven": "1991-1-1"      };
      for(let i = 0; i< kn; i++){
        const response = await axios.post("https://mindescape.herokuapp.com/api/me/Keys/", json);
        console.log(response);
        allData2[0].keys.push( response.data.id);
      }

      let json2 = {          "companyName": allData2[0].companyName,          "keys":  allData2[0].keys      };
      const response3= await axios.put("https://mindescape.herokuapp.com/api/me/Company/"+ allData2[0].id+"/",json2 );


    // setValue(newValue);
  };


  const getAllData = async () => {
   
    const response = await axios.get("https://mindescape.herokuapp.com/api/me/Position/");
    const allDataP = response.data;

    // console.log(allDataP);
        

        if(user.is_superuser){

          const response = await axios.get("https://mindescape.herokuapp.com/api/me/User/");
          const allData = response.data;
      
      
          // getDatas(allData);
      
          var row = [];
          var row2 = [];
          var pid = 0;
          // var col = [];
       
      
          for (var i in allData) {
            var dictionary = {};
           
      
            if (allData[i].user != undefined) {


          dictionary["id"] = allData[i].id;
          dictionary["employee"] = allData[i].user;

          // dictionary2["id"] = allData[i].id;
       

          
          // console.log(allData[i].user);

          const response2 = await axios.get("https://mindescape.herokuapp.com/api/me/M/1?username="+allData[i].user);
          // M/1?username=MBAy1rhjce1cl
          const allData3 = response2.data;

         
          const allDataO = [];
          const allDataC = [];
          const allDataE = [];
          const allDataA = [];
          const allDataN = [];
          
          // console.log(allData3);

          if( allData3.length !== 0){
            console.log(allData3);
           
          
    for (var l in allData3) {
          var datum = allData3[l];

          console.log(datum);

          switch(datum.room) {
            case "O":
              allDataO.push(datum);
              break;
              case "C":
                allDataC.push(datum);
                break;
                case "E":
                  allDataE.push(datum);
              break;
              case "A":
                allDataA.push(datum);
              break;
              case "N":
                allDataN.push(datum);
              break;
          }
        }

        // console.log(allDataC);
        // console.log(allDataN);

          allDataO.sort((a,b) => a.id - b.id);
          allDataC.sort((a,b) => a.id - b.id);
          allDataE.sort((a,b) => a.id - b.id);
          allDataA.sort((a,b) => a.id - b.id);
          allDataN.sort((a,b) => a.id - b.id);

         
                
        var O,C,E,A,N;
        var O1,O2,O3,O4,O5;
        var C1,C2,C3,C4,C5;
        var E1,E2,E3,E4,E5;
        var A1,A2,A3,A4,A5;
        var N1,N2,N3,N4,N5;

        if(allDataO[0] != undefined){
          O1 = allDataO[0].O;
          C1= allDataO[0].C;
          E1= allDataO[0].E;
          A1= allDataO[0].A;
          N1= allDataO[0].N;
        }else{
          O1 = 0;
          C1= 0;
          E1= 0;
          A1= 0;
          N1= 0;
        }

        if(allDataC[0] != undefined){
          O2 = allDataC[0].O;
          C2= allDataC[0].C;
          E2= allDataC[0].E;
          A2= allDataC[0].A;
          N2= allDataC[0].N;
        }else{
          O2 = 0;
          C2= 0;
          E2= 0;
          A2= 0;
          N2= 0;
        }

        if(allDataE[0] != undefined){
          O3 = allDataE[0].O;
          C3= allDataE[0].C;
          E3= allDataE[0].E;
          A3= allDataE[0].A;
          N3= allDataE[0].N;
        }else{
          O3 = 0;
          C3= 0;
          E3= 0;
          A3= 0;
          N3= 0;
        }

        if(allDataA[0] != undefined){
          O4 = allDataA[0].O;
          C4= allDataA[0].C;
          E4= allDataA[0].E;
          A4= allDataA[0].A;
          N4= allDataA[0].N;
        }else{
          O4 = 0;
          C4= 0;
          E4= 0;
          A4= 0;
          N4= 0;
        }

        if(allDataN[0] != undefined){
          O5 = allDataN[0].O;
          C5= allDataN[0].C;
          E5= allDataN[0].E;
          A5= allDataN[0].A;
          N5= allDataN[0].N;
        }else{
          O5 = 0;
          C5= 0;
          E5= 0;
          A5= 0;
          N5= 0;
        }
        

        if(O2 > 1000){
          O2 =1
        }

        if(O5 > 1000){
         O5 = 1
        }

        console.log("----------")


        O = (((O1/8)*0.8 + (O2/8)*0.1 + (O5/8)*0.1) * 100).toFixed();
        C = (( (Math.abs(C1)/4)*0.3 + ( Math.abs(C2)/4)*0.6 + ( Math.abs(C5)/4)*0.1) * 100).toFixed();
        E = ((( E1/12)*0.3 + ( E2/12)*0.6 + (E5/12)*0.1) * 100).toFixed();
        A = (( (Math.abs(A1)/6)*0.3 + (Math.abs(A2)/6)*0.6 + (Math.abs(C2)/6)*0.1) * 100).toFixed();
        N = ((( N1/7)*0.3 + ( N2/7)*0.6 + (N5/7)*0.1) * 100).toFixed();

        console.log(C)
        console.log(N)

        // if( C > 100){
        //  C = C / 10
        // }

        if( C > 1000){
          C = C / 100
         }

        
        // if( N > 100){
        //   N = N / 10
        // }

        if( N > 1000){
          N = N / 100
        }

            var min = 0;
            var bestPosition = "";
            var fit = 0;

          for (var j in allDataP) {

            // console.log(allDataP);

            var pO = allDataP[j].O;
            var pC = allDataP[j].C;
            var pE = allDataP[j].E;
            var pA = allDataP[j].A;
            var pN = allDataP[j].N;
            
            var dO = 0;
            var dC = 0;
            var dE = 0;
            var dA = 0;
            var dN = 0;

            var dictionary2 = {};

       
            dictionary2["employee"] = allData[i].user;
            dictionary2["id"] = pid;
            dictionary2["position"] = allDataP[j].positionName;


              // if(allData3[0] != undefined ){

                // console.log(allData3[0].O);

                dO = Math.abs(pO -  O);   
                dC = Math.abs(pC -  C); 
                dE = Math.abs(pE -  E);   
                dA = Math.abs(pA -  A);   
                dN = Math.abs(pN -  N);   




              // if(allData3[0].O != 0){


                // dO = Math.abs(pO -  allData3[0].O);
               
                // // console.log(allDataP[j].positionName);
       
                // if(pO > (allData[0].O/8)*100 ){
                //   fit = ( Math.abs(allData3[0].O) / pO)*100;
                //   }else{
                //     fit = (pO/ Math.abs(allData3[0].O) )*100;
                //   }    

                // if( dO < min){
                //   min = dO;
                //   bestPosition = allDataP[j].positionName;
                //   // if(pO > allData3[0].O ){
                //   // fit = ( Math.abs(allData3[0].O) / pO)*100;
                //   // }else{
                //   //   fit = (pO/ Math.abs(allData3[0].O) )*100;
                //   // }             
                // }
                // console.log(pO);
                // console.log(allData3[0].O);
                // console.log(fit);.

                  
                if(pO > O ){
                  // console.log("1");
                  fitO = ( Math.abs(O) / pO)*100;
                  }else{
                    fitO = (pO / Math.abs(O)*100 );
                  }  

                  
                  if(pC > C ){
                    // console.log("1");
                    fitC = ( Math.abs(C) / pC)*100;
                    }else{
                      fitC = (pC / Math.abs(C)*100 );
                    }  

                    if(pE > E ){
                      // console.log("1");
                      fitE = ( Math.abs(E) / pE)*100;
                      }else{
                        fitE = (pE / Math.abs(E)*100 );
                      }  

                      
                    if(pA > A ){
                      // console.log("1");
                      fitA = ( Math.abs(A) / pA)*100;
                      }else{
                        fitA = (pA / Math.abs(A)*100 );
                      }  

                      if(pN > N ){
                        // console.log("1");
                        fitN = ( Math.abs(N) / pN)*100;
                        }else{
                          fitN = (pN / Math.abs(N)*100 );
                        }  

                //         console.log(dO + " - " + dC+ " - " + dE+ " - " + dA+ " - " + dN);

                // console.log(fitO + " - " + fitC+ " - " + fitE+ " - " + fitA+ " - " + fitN);
                

                // console.log(fitO*0.24 +  fitC * 0.24  + fitE * 0.1+ fitA *0.1+  fitN * 0.24);
                // console.log( (dO + dC+  dE+  dA+  dN) / 5);
                // console.log((fitO +  fitC  + fitE + fitA +  fitN )/5);
                // console.log("-");
                // console.log( fit);

                fit = fitO*0.24 +  fitC * 0.24  + fitE * 0.1+ fitA *0.1+  fitN * 0.24;
                difAll = (dO + dC+  dE+  dA+  dN) / 5;
                // if(pO > O ){
                //   // console.log("1");
                //   fit = ( Math.abs(O) / pO)*100;
                //   }else{
                //     fit = (pO / Math.abs(O)*100 );
                //   }  
                // console.log(allDataP[j].positionName);

              if( difAll > min){
                min = difAll;
                bestPosition = allDataP[j].positionName;
                dictionary["FIT"] = bestPosition;
                dictionary["FIT%"] = fit.toFixed(2);
              }
                
                dictionary2["FIT"] = fit.toFixed(2);
                dictionary2["FIT2"] = < ProgressBar completed= {fit.toFixed(2)} bgColor="#af9b2a"/>                


                pid++;

                // console.log(row2);
                row2.push(dictionary2);
              // }          
            
            
    
              // }

              

          }

          dictionary["FIT"] = bestPosition;
          dictionary["FIT%"] = fit.toFixed(2);

          

          dictionary["O"] = "";
          
          dictionary["C"] = "";
          
          dictionary["E"] = "";
          dictionary["A"] = "";
          
          dictionary["N"] = "";

          // if(allData3[0] != undefined ){

            // console.log(allData3[0].O);

            dictionary["O"] = O;
            dictionary["C"] = C;
            dictionary["E"] = E;
            dictionary["A"] = A;
            dictionary["N"] = N;

          // }


        }

         if (allData[i].user.startsWith("DPMS")) {
          dictionary["manager"] = "DPMS";
        } else if (allData[i].user.startsWith("MBA")) {

          dictionary["manager"] = "MBA";

        } else if (allData[i].user.startsWith("BA")) {
          dictionary["manager"] = "BA";
        } else if (allData[i].user.startsWith("I")) {
          dictionary["manager"] = "Italy";
        } 
        else if (allData[i].user.startsWith("D")) {
          dictionary["manager"] = "Diploma";
        }else {
          dictionary["manager"] = "Site";
        }


        // console.log(dictionary);
        // console.log(dictionary2);
          // dictionary["manager"] = "Masoutis";
          row.push(dictionary);
          setLoading(false);


        }
        
      //   else{

      //     const response2 = await axios.get("https://mindescape.herokuapp.com/api/me/C/1?id="+user.id);
      //     // M/1?username=MBAy1rhjce1cl
      //     const allData2 = response2.data;
    
      //     console.log(allData2);
    
      //     let cempl = allData2[0].empl

      //     if ( cempl.indexOf(allData[i].id) != -1) {
      //   // if (allData[i].user.startsWith("M")) {
      //     dictionary["id"] = allData[i].id;
      //     dictionary["employee"] = allData[i].user;
      //     dictionary["manager"] =  allData2[0].companyName;
      //     row.push(dictionary);
      //   }
      // }
        //  else if (allData[i].user.startsWith("DPMS")) {
        //   dictionary["manager"] = "DPMS";
        // } else if (allData[i].user.startsWith("MBA")) {

        //   dictionary["manager"] = "MBA";

        // } else if (allData[i].user.startsWith("BA")) {
        //   dictionary["manager"] = "BA";
        // } else if (allData[i].user.startsWith("I")) {
        //   dictionary["manager"] = "Italy";
        // } 
        // else if (allData[i].user.startsWith("D")) {
        //   dictionary["manager"] = "Diploma";
        // }else {
        //   dictionary["manager"] = "Site";
        // }
      }

      // dictionary["position"] = "-";

      // if( dictionary !== undefined){
      // console.log(dictionary);

      // row.push(dictionary);
      // }

    }else{

      var row = [];
      var row2 = [];
      var row3 = [];
      var pid = 0;


      const response2 = await axios.get("https://mindescape.herokuapp.com/api/me/C/1?id="+user._id);
      //     // M/1?username=MBAy1rhjce1cl
          const allData2 = response2.data;
    
          // console.log(allData2);

          let cempl = allData2[0].empl

          // console.log(cempl);


          
          var row = [];

          for (var j in cempl) {

            var dictionary = {};

            // console.log(cempl[j]);

            let uid = cempl[j]

            const response = await axios.get("https://mindescape.herokuapp.com/api/me/User/" + uid);

            // console.log(response.data);

            let user = response.data;

           


          const response2 = await axios.get("https://mindescape.herokuapp.com/api/me/M/1?username="+user.user);
          // M/1?username=MBAy1rhjce1cl
          const allData4 = response2.data;

          // console.log(allData4);

          if( allData4.length != 0){

            // console.log("DATA");

            dictionary["id"] = user.id;
            dictionary["employee"] =user.user;
            dictionary["manager"] =   allData2[0].companyName;
          
          // }else{
          //   console.log("DATA");
          // }


          const allDataO = [];
          const allDataC = [];
          const allDataE = [];
          const allDataA = [];
          const allDataN = [];

     
          
    for (var l in allData4) {
          var datum = allData4[l];

    


          // console.log("------------");
          // console.log(datum.user);
          // console.log(datum);

          switch(datum.room) {
            case "O":
              allDataO.push(datum);
              break;
              case "C":
                allDataC.push(datum);
                break;
                case "E":
                  allDataE.push(datum);
              break;
              case "A":
                allDataA.push(datum);
              break;
              case "N":
                allDataN.push(datum);
              break;
          }
        }

          allDataO.sort((a,b) => a.id - b.id);
          allDataC.sort((a,b) => a.id - b.id);
          allDataE.sort((a,b) => a.id - b.id);
          allDataA.sort((a,b) => a.id - b.id);
          allDataN.sort((a,b) => a.id - b.id);
          var O,C,E,A,N;
          var O1,O2,O3,O4,O5;
          var C1,C2,C3,C4,C5;
          var E1,E2,E3,E4,E5;
          var A1,A2,A3,A4,A5;
          var N1,N2,N3,N4,N5;

          // console.log(allDataO);
          // console.log(allDataC);
          // console.log(allDataE);
          // console.log(allDataA);
          // console.log(allDataN);
  
          if(allDataO[0] != undefined){
            O1 = allDataO[0].O;
            C1= allDataO[0].C;
            E1= allDataO[0].E;
            A1= allDataO[0].A;
            N1= allDataO[0].N;
          }else{
            O1 = 0;
            C1= 0;
            E1= 0;
            A1= 0;
            N1= 0;
          }
  
          if(allDataC[0] != undefined){
            O2 = allDataC[0].O;
            C2= allDataC[0].C;
            E2= allDataC[0].E;
            A2= allDataC[0].A;
            N2= allDataC[0].N;
          }else{
            O2 = 0;
            C2= 0;
            E2= 0;
            A2= 0;
            N2= 0;
          }
  
          if(allDataE[0] != undefined){
            O3 = allDataE[0].O;
            C3= allDataE[0].C;
            E3= allDataE[0].E;
            A3= allDataE[0].A;
            N3= allDataE[0].N;
          }else{
            O3 = 0;
            C3= 0;
            E3= 0;
            A3= 0;
            N3= 0;
          }
  
          if(allDataA[0] != undefined){
            O4 = allDataA[0].O;
            C4= allDataA[0].C;
            E4= allDataA[0].E;
            A4= allDataA[0].A;
            N4= allDataA[0].N;
          }else{
            O4 = 0;
            C4= 0;
            E4= 0;
            A4= 0;
            N4= 0;
          }
  
          if(allDataN[0] != undefined){
            O5 = allDataN[0].O;
            C5= allDataN[0].C;
            E5= allDataN[0].E;
            A5= allDataN[0].A;
            N5= allDataN[0].N;
          }else{
            O5 = 0;
            C5= 0;
            E5= 0;
            A5= 0;
            N5= 0;
          }
          
  
          if(O2 > 1000){
            O2 =0
          }
  
          if(O5 > 1000){
           O5 = 0
          }
  
          O = (((O1/8)*0.8 + (O2/8)*0.1 + (O5/8)*0.1) * 100).toFixed();
        C = (( (Math.abs(C1)/4)*0.3 + ( Math.abs(C2)/4)*0.6 + ( Math.abs(C5)/4)*0.1) * 100).toFixed();
        E = ((( E1/12)*0.3 + ( E2/12)*0.6 + (E5/12)*0.1) * 100).toFixed();
        A = (( (Math.abs(A1)/6)*0.3 + (Math.abs(A2)/6)*0.6 + (Math.abs(C2)/6)*0.1) * 100).toFixed();
        N = ((( N1/7)*0.3 + ( N2/7)*0.6 + (N5/7)*0.1) * 100).toFixed();

        // console.log(datum);

            // console.log(O + " - " + C+ " - " + E+ " - " + A+ " - " + N);

        // console.log(C)
        // console.log(N)

        // if( C > 100){
        //  C = C / 10
        // }

        if( C >= 1000){
          C = C / 100
         }

        
        // if( N > 100){
        //   N = N / 10
        // }

        if( N >= 1000){
          N = N / 100
        }

        
        if( A >= 1000){
          A = A / 100
        }

        
        if( O >= 100){
          O = O / 10
        }

        if( C >= 100){
         C = C / 10
        }

        if( A >= 100){
          A =A / 10
         }
        // console.log( O*100 );    
        // console.log( C *100);    
        // console.log( E *100);    
        // console.log( A *100);    
        // console.log( N *100);  
          // console.log(allData4)

              // console.log("****************");

          var min = 0;
          var bestPosition = "";
      
          var fit = 0;
          var fitO = 0;
          var fitC = 0;
          var fitE = 0;
          var fitA = 0;
          var fitN = 0;

          // var min = 100;
          // var bestPosition = "";
          var fit = 0;


          for (var k in allDataP) {

            var pO = allDataP[k].O;
            var pC = allDataP[k].C;
            var pE = allDataP[k].E;
            var pA = allDataP[k].A;
            var pN = allDataP[k].N;

              // console.log(allDataP[k]);
            
            var dO = 0;
            var dC = 0;
            var dE = 0;
            var dA = 0;
            var dN = 0;
            var difAll = 0;

            var dictionary2 = {};
            

            dictionary2["employee"] = user.user;
            dictionary2["id"] = pid;
            dictionary2["position"] = allDataP[k].positionName;


              // if(allData4[0] != undefined ){

                
              // if(O != 0){

                //  console.log(pO);
                //  console.log(pC);
                //  console.log(pE);
                //  console.log(pA);
                //  console.log(pN);

                // console.log(allData4[0].O);


                dO = Math.abs(pO -  O);   
                dC = Math.abs(pC -  C); 
                dE = Math.abs(pE -  E);   
                dA = Math.abs(pA -  A);   
                dN = Math.abs(pN -  N);   

                // console.log("-");
                // console.log(dO);
                // console.log(dC);
                // console.log(dE);
                // console.log(dA);
                // console.log(dN);
               
                // console.log(allDataP[j].positionName);
             
                // setOpen((allData[0].O.toString()/8)*100);
                // setCons((allData[0].C/4)*100);
                // setExtr((allData[0].E/9)*100);
                // setAgrr((allData[0].A/3)*100);
                // setNeur((allData[0].N/7)*100);

                // console.log(pO);
                // console.log((allData4[0].O/8)*100);

               

               
                  if(pO > O ){
                    // console.log("1");
                    fitO = ( Math.abs(O) / pO)*100;
                    }else{
                      fitO = (pO / Math.abs(O)*100 );
                    }  

                    
                    if(pC > C ){
                      // console.log("1");
                      fitC = ( Math.abs(C) / pC)*100;
                      }else{
                        fitC = (pC / Math.abs(C)*100 );
                      }  

                      if(pE > E ){
                        // console.log("1");
                        fitE = ( Math.abs(E) / pE)*100;
                        }else{
                          fitE = (pE / Math.abs(E)*100 );
                        }  

                        
                      if(pA > A ){
                        // console.log("1");
                        fitA = ( Math.abs(A) / pA)*100;
                        }else{
                          fitA = (pA / Math.abs(A)*100 );
                        }  

                        if(pN > N ){
                          // console.log("1");
                          fitN = ( Math.abs(N) / pN)*100;
                          }else{
                            fitN = (pN / Math.abs(N)*100 );
                          }  
  
                          // console.log(dO + " - " + dC+ " - " + dE+ " - " + dA+ " - " + dN);

                  // console.log(fitO + " - " + fitC+ " - " + fitE+ " - " + fitA+ " - " + fitN);
                  

                  // console.log(fitO*0.24 +  fitC * 0.24  + fitE * 0.1+ fitA *0.1+  fitN * 0.24);
                  // console.log( (dO + dC+  dE+  dA+  dN) / 5);
                  // // console.log((fitO +  fitC  + fitE + fitA +  fitN )/5);
                  // console.log("-");
                  // console.log( fit);

                  fit = fitO*0.24 +  fitC * 0.24  + fitE * 0.1+ fitA *0.1+  fitN * 0.24;
                  difAll = (dO + dC+  dE+  dA+  dN) / 5;
                  // if(pO > O ){
                  //   // console.log("1");
                  //   fit = ( Math.abs(O) / pO)*100;
                  //   }else{
                  //     fit = (pO / Math.abs(O)*100 );
                  //   }  

               
  
                  // console.log(allDataP[k].positionName);
                  // console.log(difAll);
                  // console.log(fit);


                if( difAll > min){
                  min = difAll;
                  bestPosition = allDataP[k].positionName;
                  dictionary["FIT"] = bestPosition;
                  dictionary["FIT%"] = fit.toFixed(2);
                }

                // console.log( min);
                
                dictionary2["FIT"] = fit.toFixed(2);

                if(fit.toFixed(2) > 50.00 ){
                  dictionary2["FIT2"] = < ProgressBar completed= {fit} bgColor="green"/>        
                }else{
                dictionary2["FIT2"] = < ProgressBar completed= {fit} bgColor="#af9b2a"/>                
                }

                pid++;

                // console.log(row2);
                row2.push(dictionary2);
                
              // }          
            
            
    
              // }

              

          }

         

          

          dictionary["O"] = "";
          
          dictionary["C"] = "";
          
          dictionary["E"] = "";
          dictionary["A"] = "";
          
          dictionary["N"] = "";

          // if(allData4[0] != undefined ){

            // console.log(allData3[0].O);

          dictionary["O"] = O;
          dictionary["C"] = C;
          dictionary["E"] = E;
          dictionary["A"] = A;
          dictionary["N"] = N;

          // }


          // dictionary["O"] = allData4[0].Ο;

          // console.log(dictionary);
          row.push(dictionary);

          }

      //     if ( cempl.indexOf(allData[i].id) != -1) {
      //   // if (allData[i].user.startsWith("M")) {
      //     dictionary["id"] = allData[i].id;
      //     dictionary["employee"] = allData[i].user;
      //     dictionary["manager"] =  allData2[0].companyName;
      //     row.push(dictionary);
      //   }
        }

        let keys = allData2[0].keys

        // console.log(allData2[0]);

        let keysUsed = allData2[0].keysUsed

        // console.log(keys);

        // console.log(keysUsed);

        setkeys(keys.length);

        setkeysUsed(keysUsed.length);



        for (var k in keys) {

          var dictionary3 = {};

          // console.log(keys[k]);

          let kid = keys[k]

        const responseK = await axios.get("https://mindescape.herokuapp.com/api/me/Keys/" + kid);

        const allDataK = responseK.data;

          // console.log(allDataK);

          // if( allData4.length != 0){

            // console.log("DATA");

            dictionary3["id"] = allDataK.id;
            dictionary3["code"] = allDataK.code;
            dictionary3["dateCreated"] =   allDataK.dateCreated;
            // dictionary3["dateGiven"] =   allDataK.dateGiven;

            row3.push(dictionary3);

        }



    }
    if( row != undefined){
    // console.log(row);
    setTRows(row);
    rows = row;
    }

    if( row2 != undefined){
      // console.log(row);
      setTRows2(row2);
      // rows = row;
      }
    if( row3 != undefined){
      // console.log(row);
      setTRows3(row3);
      // rows = row;
      }
    

  };
    
  React.useEffect(() => {
    getAllData();
  }, []);

  function getButton2(params) {
    // console.log("ok")
    // console.log(params)
    return (   
      <Box sx={{ flexGrow: 1 }}>
       {params.row.FIT > 50 &&
        < ProgressBar completed= { params.row.FIT } bgColor="green"/> 
       } 
       {params.row.FIT < 50 &&
        < ProgressBar completed= { params.row.FIT } bgColor="#af9b2a"/> 
       }
     
      
      </Box>
    );
  }

  function getButton(params) {
    // console.log("ok")
    // console.log(params.employee)
    return (   
    <Link to={{ pathname: '/statistics', search: `id=${params.id}&employee=${params.row.employee}` }}>  
     <Button>STATISTICS</Button>
    </Link>
    );
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'manager', headerName: 'SECTOR', width: 200 },
    { field: 'employee', headerName: 'EMPLOYEE', width: 300 },    
    // { field: 'O', headerName: 'O', width: 100 },
    // { field: 'C', headerName: 'C', width: 100 },
    // { field: 'E', headerName: 'E', width: 100 },
    // { field: 'A', headerName: 'A', width: 100 },
    // { field: 'N', headerName: 'N', width: 100 },
    { field: 'FIT', headerName: ' BEST POSITION', width: 300  },
    { field: 'FIT%', headerName: 'FIT %', width: 300 },
    // { field: 'position', headerName: 'POSITION', width: 200 },
    {
      field: "button",
      headerName: ' ',
      renderCell: (params) => {
        // console.log(params.row)
        return getButton(params);
      },
    },
  ];

  const columns2 = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'position', headerName: 'POSITION', width: 250 },
    // { field: 'manager', headerName: 'SECTOR', width: 150 },
    { field: 'employee', headerName: 'EMPLOYEE', width: 300 },    
    // { field: 'O', headerName: 'O', width: 100 },
    // { field: 'C', headerName: 'C', width: 100 },
    // { field: 'E', headerName: 'E', width: 100 },
    // { field: 'A', headerName: 'A', width: 100 },
    // { field: 'N', headerName: 'N', width: 100 },
    // { field: 'FIT', headerName: 'FIT', width: 250 },
    { field: 'FIT', headerName: 'FIT %', width: 200 },
    { field: 'FIT2', headerName: '',  renderCell: (params) => { 
       return getButton2(params);
      }, width: 500 
     },
    {
      field: "button",
      headerName: ' ',
      renderCell: (params) => {
        // console.log(params.row)
        return getButton(params);
      },
    },
   ];

   const columns3 = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'code', headerName: 'Code', width: 250 },
    // { field: 'manager', headerName: 'SECTOR', width: 150 },
    { field: 'dateCreated', headerName: 'dateCreated', width: 300 },    
    // { field: 'O', headerName: 'O', width: 100 },
    // { field: 'C', headerName: 'C', width: 100 },
    // { field: 'E', headerName: 'E', width: 100 },
    // { field: 'A', headerName: 'A', width: 100 },
    // { field: 'N', headerName: 'N', width: 100 },
    // { field: 'FIT', headerName: 'FIT', width: 250 },
    // { field: 'dateGiven', headerName: 'dateGiven', width: 200 },  
   ];
  // const rows = [
  //   { id: 1, employee: "d", manager: 'Jon', position: 35 },
  //   // { id: 2, employee: 'Lannister', manager: 'Cersei', position: 42 },
  //   // { id: 3, employee: 'Lannister', manager: 'Jaime', position: 45 },
  //   // { id: 4, employee: 'Stark', manager: 'Arya', position: 16 },
  //   // { id: 5, employee: 'Targaryen', manager: 'Daenerys', position: null },
  //   // { id: 6, employee: 'Melisandre', manager: null, position: 150 },
  //   // { id: 7, employee: 'Clifford', manager: 'Ferrara', position: 44 },
  //   // { id: 8, employee: 'Frances', manager: 'Rossini', position: 36 },
  //   // { id: 9, employee: 'Roxie', manager: 'Harvey', position: 65 },
  // ];

  // console.log("oks")
  // console.log(rows)
  // console.log(Trows)
  return (
    <>
         {/* { Trows != undefined && */}
        <TabContext value={value}> 
        <Button onClick={e=>createKeys(e)}>Add User</Button>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Employees" value="1" />
            <Tab label="Positions" value="2" />
            {/* <Tab label="Availiable Keys" value="3" /> */}
          </TabList>
        </Box>
        <TabPanel value="1"> 
        <div style={{ height: 600, display: "flex", justifyContent: "center", overflow: "hidden", borderRadius:10 }}>
     
      

        <DataGrid
          sx={{
            boxShadow: 2,
            display: "flex",
            justifyContent: "center",
          }}
          rows={Τrows}
          columns={columns}
          headerAlign="center"
          pageSize={25}
          color='secondary'
          rowsPerPageOptions={[25]}
         
          // checkboxSelection
        />
       
        </div>
        </TabPanel>

        <TabPanel value="2"> 
        <div style={{ height: 800, display: "flex", justifyContent: "center", overflow: "hidden", borderRadius:10 }}>
     
      
        {/* {loading ? ( */}
        <DataGrid
          sx={{
            boxShadow: 2,
            display: "flex",
            justifyContent: "center",
          }}
          rows={Τrows2}
          columns={columns2}
          headerAlign="center"
          pageSize={50}
          color='secondary'
          rowsPerPageOptions={[50]}
          initialState={{
            sorting: {
              sortModel: [{ field: 'FIT', sort: 'desc' }],
            },
          }}
          // checkboxSelection
        />
        {/* ) : (
          <div />
        )} */}
        </div>
        </TabPanel>

        <TabPanel value="3"> 

        <div style={{ height: 800, display: "flex", justifyContent: "center", overflow: "hidden", borderRadius:10 }}>

      

        <DataGrid
          sx={{
            boxShadow: 2,
            display: "flex",
            justifyContent: "center",
          }}
          rows={Τrows3}
          columns={columns3}
          headerAlign="center"
          pageSize={25}
          color='secondary'
          rowsPerPageOptions={[25]}
         
          // checkboxSelection
        />

        </div>
        </TabPanel>
     
        </TabContext>


        {/* } */}
    
      {/* <Table columns={Τcols} rows={Τrows} />{" "} */}
    </>
  );
}
